
export default [
    {
        "id": -3,
        "shikenId": -1,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "次のうち、最も適切なものを選びなさい。",
        "question2": "１＋１は２である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "choice4": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": -2,
        "shikenId": -1,
        "parentNo": 1,
        "no": 2,
        "answerType": 1,
        "inputType": 1,
        "question1":  "次のうち、最も適切なものを選びなさい。",
        "question2": "１＋１＝",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": -1,
        "shikenId": 0,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "次のうち、最も適切なものを選びなさい。",
        "question2": "１＋１は２である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "choice4": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 0,
        "shikenId": 0,
        "parentNo": 1,
        "no": 2,
        "answerType": 1,
        "inputType": 1,
        "question1":  "次のうち、最も適切なものを選びなさい。",
        "question2": "１＋１＝",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "",
        "answer": 2,
        "score": 1
    },
    {
		"id": 1,
		"shikenId": 1,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "税理士資格を有しないファイナンシャル・プランナーが顧客からの要望により確定申告書を作成した場合、それが無償であれば税理士法に抵触しない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 2,
		"shikenId": 1,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "労働者災害補償保険（労災保険）の保険料は、労使折半で負担する。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 3,
		"shikenId": 1,
		"parentNo": 1,
		"no": 3,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "公的介護保険の第１号被保険者が介護保険を利用し、サービスを受けた場合の自己負担割合は、所得の多寡に関わらず２割である。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 4,
		"shikenId": 1,
		"parentNo": 1,
		"no": 4,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "確定拠出年金（個人型）の老齢給付金を一時金で受け取った場合、退職所得として所得税の課税対象となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 5,
		"shikenId": 1,
		"parentNo": 1,
		"no": 5,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "国民年金の付加保険料納付期間を有する者が、老齢基礎年金の繰上げ支給の申出をした場合、付加年金は、老齢基礎年金と同様の減額率によって減額される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 6,
		"shikenId": 1,
		"parentNo": 1,
		"no": 6,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "個人年金保険の契約を銀行の窓口で行った場合、生命保険契約者保護機構の保護の対象とならない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 7,
		"shikenId": 1,
		"parentNo": 1,
		"no": 7,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "延長（定期）保険とは保険料の払込みを中止し、その時点での解約返戻金を一時払い保険料として充当することで死亡保障額が元の契約と同じ定期保険に変更する方法である。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 8,
		"shikenId": 1,
		"parentNo": 1,
		"no": 8,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "逓減定期保険は、保険期間が経過するほど保険金額および保険料が減少する。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 9,
		"shikenId": 1,
		"parentNo": 1,
		"no": 9,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "国内旅行傷害保険では、国内旅行中に発生した地震や津波によるケガも補償の対象としている。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 10,
		"shikenId": 1,
		"parentNo": 1,
		"no": 10,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "生命保険の保険料は、純保険料と付加保険料で構成されているが、純保険料は予定死亡率を用いて計算され、付加保険料は予定利率や予定事業費率を用いて計算される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 11,
		"shikenId": 1,
		"parentNo": 1,
		"no": 11,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "日経平均株価は、東京証券取引所プライム市場に上場する銘柄のうちから、市場流動性の高い225銘柄を選定し、その株価を使って算出する指数である。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 12,
		"shikenId": 1,
		"parentNo": 1,
		"no": 12,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "国内総生産（GDP）は、一定期間内に国内で生産された財やサービスの付加価値の総額をあらわすため、海外で生産された付加価値は含まない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 13,
		"shikenId": 1,
		"parentNo": 1,
		"no": 13,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "市場金利の変動に伴う債券価格の変動幅は、他の条件が同じであれば、短期利付債券よりも長期利付債券の方が大きくなる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 14,
		"shikenId": 1,
		"parentNo": 1,
		"no": 14,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "証券取引所を通じて株式を売買する場合、指値注文よりも成行注文が優先される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 15,
		"shikenId": 1,
		"parentNo": 1,
		"no": 15,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "2024年１月１日より開始した新しいNISA制度の成長投資枠における、年間非課税投資額の限度は240万円である。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 16,
		"shikenId": 1,
		"parentNo": 1,
		"no": 16,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "税金を負担する者と税金を納める者が違う税金を間接税といい、代表的なものに消費税や所得税などがある。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 17,
		"shikenId": 1,
		"parentNo": 1,
		"no": 17,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "減価償却方法について税務署長に届出をしなかった場合、所得税における法定償却方法は、定率法となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 18,
		"shikenId": 1,
		"parentNo": 1,
		"no": 18,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "納税者の配偶者が青色事業専従者や事業専従者である場合は、配偶者控除の適用を受けることができない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 19,
		"shikenId": 1,
		"parentNo": 1,
		"no": 19,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "不動産の貸付を事業的規模（貸家でいうと５棟以上、アパートやマンションでいうと、10室以上）で行っている場合、事業所得となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 20,
		"shikenId": 1,
		"parentNo": 1,
		"no": 20,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "不動産の登記事項証明書の交付請求は、インターネットを利用してオンラインで行うことができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 21,
		"shikenId": 1,
		"parentNo": 1,
		"no": 21,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "登記に所有者と記載されている者が真の所有者と異なる場合、売買契約を交わした買主は必ずしも法的な保護を受けられない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 22,
		"shikenId": 1,
		"parentNo": 1,
		"no": 22,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "不動産の売買契約を解除する場合、相手方が契約の履行に着手した後であっても、買主は手付金の放棄、売主はその倍額を現実に提供することにより契約を解除することができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 23,
		"shikenId": 1,
		"parentNo": 1,
		"no": 23,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "市街化調整区域において開発を行う場合、その規模が1,000㎡未満であれば都道府県知事の許可を受ける必要はない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 24,
		"shikenId": 1,
		"parentNo": 1,
		"no": 24,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "マンションの所有者が当該建物の賃貸を自ら業として行う場合、宅地建物取引業の免許を取得する必要はない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 25,
		"shikenId": 1,
		"parentNo": 1,
		"no": 25,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "防火地域または準防火地域に耐火建築物を建築した場合、建蔽率だけではなく、容積率の制限についても緩和を受けることができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 26,
		"shikenId": 1,
		"parentNo": 1,
		"no": 26,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "書面によって締結した贈与契約の場合、すでに履行している部分だけではなく、まだ履行していない部分についても解除することができない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 27,
		"shikenId": 1,
		"parentNo": 1,
		"no": 27,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "父からの贈与について相続時精算課税制度の適用を受けている者が、母からの贈与について暦年課税を選択することは可能である。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 28,
		"shikenId": 1,
		"parentNo": 1,
		"no": 28,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "配偶者が相続により取得した財産の価格が、１億6,000万円以下または配偶者の法定相続分以下の場合、配偶者に相続税は課税されない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 29,
		"shikenId": 1,
		"parentNo": 1,
		"no": 29,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "個人が法人からの贈与により財産を取得した場合、取得した財産は贈与税の課税対象となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 30,
		"shikenId": 1,
		"parentNo": 1,
		"no": 30,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "個人間での取引において、時価と比べて著しく低い対価での譲渡があった場合は、原則として、時価と譲渡対価の差額に贈与税が課税される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 31,
		"shikenId": 1,
		"parentNo": 2,
		"no": 31,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "利率2.0％で複利運用しながら毎年一定金額を積み立てて10年後に8,000,000円を準備したい。<br>この場合に最低限必要な毎年の積立金額を下記の〈資料〉を利用して算出すると（　）となる。<br><br>〈資料〉<br><img src='/img/31.png' width='550'>",
		"choice1": "1）730,640円",
		"choice2": "2）824,240円",
		"choice3": "3）898,300円",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 32,
		"shikenId": 1,
		"parentNo": 2,
		"no": 32,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "業務外での病気やケガの療養や治療のため、連続して（ ① ）仕事を休んでいる場合に健康保険から支給される傷病手当金の金額は、休業１日につき支給開始日以前の継続した12カ月間の標準報酬月額の平均を30日で除した金額の（ ② ）となる。",
		"choice1": "1）① ２日以上 ② ２分の１",
		"choice2": "2）① ３日以上 ② ３分の２",
		"choice3": "3）① 10日以上 ② ４分の３",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 33,
		"shikenId": 1,
		"parentNo": 2,
		"no": 33,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "任意継続被保険者になるための要件は、被保険者期間が継続して２ヵ月以上ある者が資格喪失日から（ ① ）以内に申請をすることである。<br>要件を満たせば最長で（ ② ）は、任意継続被保険者となることができる。",
		"choice1": "1）① 10日 ② １年間",
		"choice2": "2）① 20日 ② ２年間",
		"choice3": "3）① １カ月 ② ３年間",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 34,
		"shikenId": 1,
		"parentNo": 2,
		"no": 34,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "住宅ローンの返済方法のうち、元利均等返済は、毎月の返済額が（ ① ）、返済期間の経過とともに毎月の元金の返済額が（ ② ）返済方法である。",
		"choice1": "1）① 減少し ② 増加する",
		"choice2": "2）① 一定で ② 減少する",
		"choice3": "3）① 一定で ② 増加する",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 35,
		"shikenId": 1,
		"parentNo": 2,
		"no": 35,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "遺族厚生年金の受給額は、老齢厚生年金の報酬比例部分を計算した額の（ ① ）に相当する額となる。また、短期要件の場合で被保険者月数が（ ② ）に満たない場合は、（ ② ）として計算する。",
		"choice1": "1）① ３分の２ ② 240月",
		"choice2": "2）① ４分の３ ② 300月",
		"choice3": "3）① ５分の４ ② 360月",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 36,
		"shikenId": 1,
		"parentNo": 2,
		"no": 36,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "定期保険特約付終身保険（更新型）の契約内容を変更せずに自動更新すると、更新後の保険料は更新前と比較すると（　）。",
		"choice1": "1）変わらない",
		"choice2": "2）高くなる",
		"choice3": "3）安くなる",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 37,
		"shikenId": 1,
		"parentNo": 2,
		"no": 37,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "変額個人年金保険は、（ ① ）の運用実績に基づいて将来受け取る年金額等が変動するが、一般に、（ ② ）については最低保証がある。",
		"choice1": "1）① 特別勘定 ② 死亡給付金額",
		"choice2": "2）① 特別勘定 ② 解約返戻金額",
		"choice3": "3）① 一般勘定 ② 解約返戻金額",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 38,
		"shikenId": 1,
		"parentNo": 2,
		"no": 38,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "自賠責保険から支払われる保険金の支払限度額（被害者１名につき）は、被害者が死亡した場合は（ ① ）、後遺障害の場合は障害の程度に応じて最高（ ② ）となる。",
		"choice1": "1）① 1,000万円 ② 2,500万円",
		"choice2": "2）① 3,000万円 ② 4,000万円",
		"choice3": "3）① 4,000万円 ② 3,000万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 39,
		"shikenId": 1,
		"parentNo": 2,
		"no": 39,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "個人賠償責任保険において、（　）などの場合は補償の対象とはならない。",
		"choice1": "1）子供がボール遊びをしていて、他人の家の窓ガラスを割ってしまった",
		"choice2": "2）自転車で走行中に歩行者に衝突し、ケガをさせてしまった",
		"choice3": "3）友人から借りていたカメラを落として壊してしまった",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 40,
		"shikenId": 1,
		"parentNo": 2,
		"no": 40,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "生命保険会社が破綻した場合、原則として、破綻した時点における（ ① ）の（ ② ）までの金額が生命保険保護機構により補償される。ただし、高予定利率契約はこの限りではない。",
		"choice1": "1）① 保険金額 ② 50％",
		"choice2": "2）① 解約返戻金 ② 80％",
		"choice3": "3）① 責任準備金 ② 90％",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 41,
		"shikenId": 1,
		"parentNo": 2,
		"no": 41,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "クーポン2.0％、残存期間４年の固定利付債券を額面100円あたり106円で購入した場合の単利最終利回りは（　）となる。なお、答は表示単位の小数点以下第３位を四捨五入している。",
		"choice1": "1）0.23％",
		"choice2": "2）0.47％",
		"choice3": "3）0.62％",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 42,
		"shikenId": 1,
		"parentNo": 2,
		"no": 42,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "２つの資産で構成されているポートフォリオにおいて、相関係数が（ ① ）である場合、２つの資産が（ ② ）値動きをするため、リスク低減効果は最大となる。",
		"choice1": "1）① −１ ② 反対の",
		"choice2": "2）① ０ ② 反対の",
		"choice3": "3）① １ ② 同じ",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 43,
		"shikenId": 1,
		"parentNo": 2,
		"no": 43,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "日本国内に本店があり、預金保険制度の対象となる金融機関に預け入れた預金であっても（　）は預金保険制度の保護の対象とならない。",
		"choice1": "1）普通預金",
		"choice2": "2）定期預金",
		"choice3": "3）外貨預金",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 44,
		"shikenId": 1,
		"parentNo": 2,
		"no": 44,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "追加型株式投資信託を基準価格10,000円で購入し、最初の決算時に500円の収益分配金が支払われた。収益分配金を支払った後の基準価格が9,700円の場合、受け取った収益分配金のうち（ ① ）が普通分配金として課税され、（ ② ）が元本払戻金として非課税となる。",
		"choice1": "1）① 500円 ② 0円",
		"choice2": "2）① 200円 ② 300円",
		"choice3": "3）① 300円 ② 200円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 45,
		"shikenId": 1,
		"parentNo": 2,
		"no": 45,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "1,000,000円を年利率３％（１年複利）で３年間運用した場合の元利合計金額は、手数料や税金等を考慮しない場合、（　）となる。",
		"choice1": "1）1,009,000円",
		"choice2": "2）1,060,900円",
		"choice3": "3）1,092,727円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 46,
		"shikenId": 1,
		"parentNo": 2,
		"no": 46,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "退職所得の計算において退職所得控除額は、勤続年数が20年までについては１年につき（ ① ）で計算し、20年を超える期間については１年につき（ ② ）で計算したものを合算する。また、勤続年数が１年未満の期間は切り上げる。",
		"choice1": "1）① 30万円 ② 60万円",
		"choice2": "2）① 40万円 ② 70万円",
		"choice3": "3）① 70万円 ② 100万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 47,
		"shikenId": 1,
		"parentNo": 2,
		"no": 47,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "Ａさんの2024年分の各種所得の金額が下記の場合、総所得金額は（　）となる。<br><br>〈Aさんの2024年分の各種所得の金額〉<br><img src='/img/47.png' width='340'>",
		"choice1": "1）270万円",
		"choice2": "2）300万円",
		"choice3": "3）470万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 48,
		"shikenId": 1,
		"parentNo": 2,
		"no": 48,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "生命保険料控除の計算において、2012年１月１日以降に契約した生命保険契約に係る介護医療保険料控除は、最高で（ ① ）となり、一般の生命保険料控除と個人年金保険料控除とを合わせた限度額は最高で（ ② ）となる。",
		"choice1": "1）① ３万円 ② ５万円",
		"choice2": "2）① ４万円 ② 12万円",
		"choice3": "3）① ５万円 ② 10万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 49,
		"shikenId": 1,
		"parentNo": 2,
		"no": 49,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "住宅借入金等特別控除の適用を受けるための要件として、住宅ローンの返済期間は（ ① ）以上でなければならない。また、この住宅が店舗併用住宅の場合は、居住の用に供する部分が（ ② ）以上でなければならない。",
		"choice1": "1）① 10年 ② ２分の１",
		"choice2": "2）① 10年 ② ３分の２",
		"choice3": "3）① 15年 ② ３分の１",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 50,
		"shikenId": 1,
		"parentNo": 2,
		"no": 50,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "居住者が国内で支払いを受ける普通預金の利子は、復興特別所得税を含む所得税と住民税を合わせて（　）の税率により源泉徴収される。",
		"choice1": "1）10.21％",
		"choice2": "2）15.315％",
		"choice3": "3）20.315％",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 51,
		"shikenId": 1,
		"parentNo": 2,
		"no": 51,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "土地や建物等の譲渡に係る譲渡所得を計算する場合、譲渡した年の１月１日における所有期間が（　）を超えるものは、長期譲渡所得に区分される。",
		"choice1": "1）３年",
		"choice2": "2）５年",
		"choice3": "3）10年",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 52,
		"shikenId": 1,
		"parentNo": 2,
		"no": 52,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "居住用財産を譲渡した場合の税金について、「長期譲渡所得における軽減税率の特例」の適用を受けた場合、長期譲渡所得金額のうち、6,000万円までの部分には、（ ① ）の所得税率が適用され、6,000万円を超える部分には（ ② ）の所得税率が適用される。なお、復興特別所得税は考慮しないものとする。",
		"choice1": "1）① ５％ ② 10％",
		"choice2": "2）① 10％ ② 15％",
		"choice3": "3）① 15％ ② 20％",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 53,
		"shikenId": 1,
		"parentNo": 2,
		"no": 53,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "投資総額（自己資金＋借入金）が１億円の賃貸用不動産を購入し、年間収入が2,500万円、年間の費用が2,000万円であった場合、この賃貸用不動産の実質利回り（NOI利回り）はいくらになるか。",
		"choice1": "1）2％",
		"choice2": "2）4％",
		"choice3": "3）5％",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 54,
		"shikenId": 1,
		"parentNo": 2,
		"no": 54,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "宅地建物取引業法によると宅地建物取引業者が依頼人と専任媒介契約を締結した場合、有効期間は（　）となる。",
		"choice1": "1）１カ月",
		"choice2": "2）３カ月",
		"choice3": "3）６カ月",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 55,
		"shikenId": 1,
		"parentNo": 2,
		"no": 55,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "区分所有建物（マンションなど）に係る登記記録に記載されている面積は壁の内側の線で囲まれた（　）によって算出される。",
		"choice1": "1）水平面積",
		"choice2": "2）内法面積",
		"choice3": "3）壁芯面積",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 56,
		"shikenId": 1,
		"parentNo": 2,
		"no": 56,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "下記の親族関係図において、民法における父Ｄさんの法定相続分は（ ① ）となり、妻Ｂさんの法定相続分は（ ② ）となる。<br><br><img src='/img/56.png' width='570'>",
		"choice1": "1）① ３分の１ ② ３分の２",
		"choice2": "2）① ４分の１ ② ４分の３",
		"choice3": "3）① ２分の１ ② ２分の１",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 57,
		"shikenId": 1,
		"parentNo": 2,
		"no": 57,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "2024年３月15日に死亡したＡさんが所有していた上場株式Ｘの１株当たりの相続税評価額は、下記の〈資料〉によると（　）となる。<br><br>＜資料＞上場株式Xの価格<br><img src='/img/57.png' width='400'>",
		"choice1": "1）417円",
		"choice2": "2）420円",
		"choice3": "3）400円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 58,
		"shikenId": 1,
		"parentNo": 2,
		"no": 58,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "相続において相続人が取得した宅地が「小規模宅地等についての相続税の課税価格の計算の特例」の対象となる特定事業用宅地等であった場合、（ ① ）を限度面積として評価額の（ ② ）を減額することができる。",
		"choice1": "1）① 200㎡ ② 50％",
		"choice2": "2）① 330㎡ ② 80％",
		"choice3": "3）① 400㎡ ② 80％",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 59,
		"shikenId": 1,
		"parentNo": 2,
		"no": 59,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "準確定申告の期限は、相続があったことを知った日の翌日から（　）以内となっている。",
		"choice1": "1）３カ月",
		"choice2": "2）４カ月",
		"choice3": "3）10カ月",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 60,
		"shikenId": 1,
		"parentNo": 2,
		"no": 60,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "被相続人の（　）が相続により財産を取得した場合、その者は相続税額の２割加算の対象となる。",
		"choice1": "1）兄弟姉妹",
		"choice2": "2）父母",
		"choice3": "3）孫（子の代襲相続人）",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 61,
		"shikenId": 1,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんは、老齢基礎年金の繰上げ支給の請求を検討している。60歳から老齢基礎年金の支給繰上げを請求する場合、受給できる金額の計算式として最も適切なものはどれか。なお年金額は2024年度価格に基づくものとする。",
		"choice1": "816,000円× 444/480 ×76％",
		"choice2": "816,000円× 444/480",
		"choice3": "816,000円× 444/480 ×58％",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 4,
		"image": "2-1.png"
	},
	{
		"id": 62,
		"shikenId": 1,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ｘ社退職後におけるＡさん夫妻の公的年金制度の取扱いについて、ファイナンシャル・プランナーが説明した次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「Ａさんは、国民年金の第１号被保険者へ種別変更の届出を行う必要があります。また、妻Ｂさんは国民年金の第３号被保険者の資格を喪失するので、第１号被保険者の手続きをする必要があります」",
		"choice2": "「Ａさんは、国民年金の保険料を納付することになります。国民年金の保険料の納付方法には、納付書による現金納付や口座振替、クレジットカードによる納付があります」",
		"choice3": "「Ａさんは、国民年金の保険料を納付することになります。国民年金の保険料は、最大５年間の保険料を前納することができ、前納期間や納付方法に応じて保険料の割引があります」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "2-1.png"
	},
	{
		"id": 63,
		"shikenId": 1,
		"parentNo": 1,
		"no": 3,
		"groupId": 1,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "公的介護保険（以下、「介護保険」という）の保険給付について、ファイナンシャル・プランナーが説明した次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「介護保険の保険者（運営主体）は市区町村であるため、介護保険の保険給付を受けるためには、市町村（特別区を含む）から要介護認定または要支援認定を受ける必要があります」",
		"choice2": "「介護保険の第２号被保険者であるＡさんが、介護サービスの提供を受けた場合、原則として、実際にかかった費用の２割または３割を自己負担する必要があります」",
		"choice3": "「40歳以上65歳未満の第２号被保険者は、要介護状態または要支援状態となった原因が、初老期における認知症脳血管疾患などの加齢に伴う特定疾病である場合に限り、介護給付または予防給付を受けることができます」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "2-1.png"
	},
	{
		"id": 64,
		"shikenId": 1,
		"parentNo": 1,
		"no": 4,
		"groupId": 2,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "新しいＮＩＳＡの「つみたて投資枠」の概要について、ファイナンシャル・プランナーがＡさんに対して説明した以下の文章の空欄①〜③に入る語句の組合せとして最も適切なものは、次のうちどれか。<br> <br> <div class='b-box'>新しいＮＩＳＡの「つみたて投資枠」は、株式投資信託や（ ① ）のうち一定の要件を満たすものが対象で、対象銘柄を指定したうえで、累積投資契約に基づく定期かつ継続的な買付けを行います。非課税投資枠は年間（ ② ）、非課税期間は（ ③ ）です。</div>",
		"choice1": "① ＥＴＦ ② 120万円 ③ 無期限",
		"choice2": "① Ｊ-ＲＥＩＴ ② 40万円 ③ 最長10年間",
		"choice3": "① ＥＴＦ ② 120万円 ③ 最長５年間",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "2-2.png"
	},
	{
		"id": 65,
		"shikenId": 1,
		"parentNo": 1,
		"no": 5,
		"groupId": 2,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "新しいＮＩＳＡを利用して購入した投資信託に係る課税関係について、ファイナンシャル・プランナーがＡさんに対して説明した次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「新しいＮＩＳＡを利用して購入した株式投資信託を解約した際に損失が生じた場合、その損失の金額は、特定口座で保有する上場株式等の譲渡益と損益通算することができません」",
		"choice2": "「新しいＮＩＳＡを利用して購入した株式投資信託の普通分配金は、非課税の対象とはならず、20.315％相当額が源泉徴収等されます」",
		"choice3": "「新しいＮＩＳＡを利用して購入した株式投資信託を、購入した年に解約した場合、当該譲渡益は非課税となります」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "2-2.png"
	},
	{
		"id": 66,
		"shikenId": 1,
		"parentNo": 1,
		"no": 6,
		"groupId": 2,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ｙ社株式の各種投資指標に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「株価の相対的な割高・割安を判断する指標として、ＰＥＲ（株価収益率）があります。〈Ｙ社に関する資料〉から算出されるY株式のＰＥＲは16倍です」",
		"choice2": "「株価の相対的な割高・割安を判断する指標として、ＰＢＲ（株価純資産倍率）があります。〈Ｙ社に関する資料〉から算出されるY株式のＰＢＲは1.6倍です」",
		"choice3": "「株価に対する１株当たりの年間配当金の割合を示す指標を配当利回りといいます。〈Ｙ社に関する資料〉から算出されるY株式の配当利回りは４％です」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 4,
		"image": "2-2.png"
	},
	{
		"id": 67,
		"shikenId": 1,
		"parentNo": 1,
		"no": 7,
		"groupId": 3,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの2024年の所得税における医療費控除に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「Ａさんが医療費控除の適用を受けるためには、確定申告をする必要があります」",
		"choice2": "「生命保険契約から支払われた入院給付金や健康保険から支給を受けた高額療養費がある場合、支払った医療費の総額からそれらの金額を控除します」",
		"choice3": "「Ａさんが2024年中に支払った医療費の金額の合計額が20万円を超えていない場合、医療費控除額は算出されません」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "2-3.png"
	},
	{
		"id": 68,
		"shikenId": 1,
		"parentNo": 1,
		"no": 8,
		"groupId": 3,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの2024年分の総所得金額は、次のうちどれか。なお、Ａさんは青色申告者ではないものとする。",
		"choice1": "680万円",
		"choice2": "620万円",
		"choice3": "586万円<br><br>〈資料〉給与所得控除額<br><img src='/img/67_1.png' width='500'>",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 4,
		"image": "2-3.png"
	},
	{
		"id": 69,
		"shikenId": 1,
		"parentNo": 1,
		"no": 9,
		"groupId": 3,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <br> <div class='b-box'>ⅰ）合計所得金額1,000万円以下のＡさんが適用を受けることができる配偶者控除の控除額は、（ ① ）である。<br> ⅱ）Ａさんが適用を受けることができる扶養控除の控除額は、（ ② ）である。<br> ⅲ）Ａさんが拠出した確定拠出年金の個人型年金の掛金は、その全額が（ ③ ）の対象となり、総所得金額から控除することができる。</div>",
		"choice1": "① 26万円 ② 38万円 ③ 社会保険料控除",
		"choice2": "① 38万円 ② 38万円 ③ 小規模企業共済等掛金控除",
		"choice3": "① 38万円 ② 63万円 ③ 社会保険料控除",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "2-3.png"
	},
	{
		"id": 70,
		"shikenId": 1,
		"parentNo": 1,
		"no": 10,
		"groupId": 4,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
		"choice1": "① 400㎡ ② 2,560㎡",
		"choice2": "① 400㎡ ② 1,600㎡",
		"choice3": "① 320㎡ ② 1,280㎡",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "2-4.png"
	},
	{
		"id": 71,
		"shikenId": 1,
		"parentNo": 1,
		"no": 11,
		"groupId": 4,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "事業用定期借地権方式に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「事業用定期借地権方式とは、Ｇ社が甲土地を契約で一定期間賃借し、Ｇ社が建物を建設する手法です。土地を手放さずに安定した地代収入を得ることができ、契約期間満了時には土地が更地で返還されます」",
		"choice2": "「事業用定期借地権方式により、Ａさんが甲土地をＧ社に賃貸した後にＡさんの相続が開始した場合、相続税の課税価格の計算上、甲土地は貸家建付地として評価されますので、相続税額の軽減効果があります」",
		"choice3": "「事業用定期借地権の設定契約は、公正証書で作成しなければなりません」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "2-4.png"
	},
	{
		"id": 72,
		"shikenId": 1,
		"parentNo": 1,
		"no": 12,
		"groupId": 4,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "不動産の価格に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "《設例》の図では相続税路線価が表示されているが、この道路に示された「200Ｄ」とは、１㎡当たりの価額が20万円、借地権割合が60％であることを示している。",
		"choice2": "土地の価格には、「実勢価格（取引価格）」 「公示価格」 「基準地標準価格」 「相続税路線価」 「固定資産税評価額」の５つがあるといわれ、このうち相続税路線価は公示価格の６割程度に設定されている。",
		"choice3": "公示価格は、毎年１月１日を基準日として、国土交通省の土地鑑定委員会が判定し、３月に公表されるものである。",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "2-4.png"
	},
	{
		"id": 73,
		"shikenId": 1,
		"parentNo": 1,
		"no": 13,
		"groupId": 5,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの相続に係る民法上の相続人およびその法定相続分の組み合わせとして最も適切なものは、次のうちどれか。",
		"choice1": "妻Ｂさん：２/３ 母Ｅさん：１/３",
		"choice2": "妻Ｂさん：１/２ 長男Ｃさん：１/４ 長女Ｄさん：１/４",
		"choice3": "妻Ｂさん：１/２ 長男Ｃさん：１/６ 長女Ｄさん：１/６ 母Ｅさん：１/６",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "2-5.png"
	},
	{
		"id": 74,
		"shikenId": 1,
		"parentNo": 1,
		"no": 14,
		"groupId": 5,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "小規模宅地等の評価減の特例（以下、「本特例」という）に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "《設例》にある自宅の土地および家屋を妻Ｂさんが相続により取得し、その土地について特定居住用宅地等として本特例の適用を受けた場合、限度面積330㎡までの部分については、評価額を80％減額することができる。",
		"choice2": "妻Ｂさんが自宅の敷地と建物を相続し、本特例の適用を受けた場合、自宅の敷地（相続税評価額5,000万円）について、相続税の課税価格に算入すべき価額は4,000万円となる。",
		"choice3": "相続税の申告期限までに分割されていない宅地には適用されないが、申告期限から５年以内に分割された場合には適用される。",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 4,
		"image": "2-5.png"
	},
	{
		"id": 75,
		"shikenId": 1,
		"parentNo": 1,
		"no": 15,
		"groupId": 5,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの相続に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "配偶者に対する相続税額の軽減の適用を受けた場合、妻Ｂさんが相続により取得した財産の金額が、配偶者の法定相続分相当額と１億6,000万円とのいずれか多い金額までであれば、納付すべき相続税額は算出されない。",
		"choice2": "Ａさんが2024年分の所得税および復興特別所得税について確定申告書を提出しなければならない場合に該当するとき、相続人は、原則として、相続の開始があったことを知った日の翌日から10カ月以内に準確定申告書を提出しなければならない。",
		"choice3": "相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から２年以内に被相続人であるＡさんの死亡時の住所地を所轄する税務署長に提出しなければならない。",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "2-5.png"
	},
	{
		"id": 76,
		"shikenId": 1,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｍさんは、《設例》に基づいて、Ａさんが60歳に達するまで国民年金の保険料を納付した場合における老齢基礎年金の年金額を試算した。Ａさんが原則として、65歳から受給することができる老齢基礎年金の年金額を試算する計算式として、次のうち最も適切なものはどれか。なお、年金額は2024年度価額に基づいて計算するものとする。",
		"choice1": "<img src='/img/question/76_1.png' style='margin-top: -10px;margin-bottom:35px;height:40px'>",
		"choice2": "<img src='/img/question/76_2.png' style='margin-top: -31px;margin-bottom:35px;height:60px'>",
		"choice3": "<img src='/img/question/76_3.png' style='margin-top: -30px;margin-bottom:0px;height:60px'>",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 4,
		"image": "3-1.png"
	},
	{
		"id": 77,
		"shikenId": 1,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｍさんは、公的介護保険（以下、「介護保険」という）について説明した。Ｍさんが、Ａさんに対して説明した次の記述のうち最も適切なものはどれか。",
		"choice1": "「介護保険の被保険者が保険給付を受けるためには、都道府県から要介護認定または要支援認定を受ける必要があります」",
		"choice2": "「介護保険の第２号被保険者であるＡさんは、原因を問わず要介護状態または要支援状態となった場合に保険給付を受けることができます」",
		"choice3": "「介護保険の第２号被保険者が保険給付を受けた場合、原則として実際にかかった費用（食費、居住費等を除く）の１割を自己負担する必要があります」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "3-1.png"
	},
	{
		"id": 78,
		"shikenId": 1,
		"parentNo": 1,
		"no": 3,
		"groupId": 1,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｍさんは、Ｘ社退職後、老後の年金収入を増やすことができる方法や各種制度について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <div class='b-box'>ⅰ）「Ａさんは、国民年金基金に加入することができます。国民年金基金の掛金の上限は、原則として、個人型年金の掛金と合わせて月額（ ① ）円となります」<br> ⅱ）「Ａさんには学生納付特例期間がありますが、学生納付特例期間について追納できるのは（ ② ）年以内の保険料です」<br> ⅲ）「Ａさんは、国民年金の定額保険料に加えて付加保険料を納付することができます。仮に、Ａさんが付加保険料を180月納付し、65歳から老齢基礎年金を受け取る場合、老齢基礎年金の額に付加年金として年額（ ③ ）円が上乗せされます」</div>",
		"choice1": "① 72,000 ② 10 ③ 24,000",
		"choice2": "① 68,000 ② 10 ③ 36,000",
		"choice3": "① 68,000 ② 20 ③ 48,000",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "3-1.png"
	},
	{
		"id": 79,
		"shikenId": 1,
		"parentNo": 1,
		"no": 4,
		"groupId": 2,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "はじめに、Ｍさんは、Ａさんに対して、現在加入している生命保険の契約内容等について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
		"choice1": "「Ａさんが現時点で病気を原因として亡くなった場合、死亡保険金3,500万円を受け取ることができます」",
		"choice2": "「遺族に必要な生活資金等の総額から遺族の収入金額等を差し引いた金額が、必要保障額として死亡保険金額を設定する目安となります。通常、必要保障額はお子様の成長とともに逓減するため、収入保障保険や逓減定期保険への加入も検討してはいかがでしょう」",
		"choice3": "「Ａさんが現時点において、脳卒中で所定の状態になったと医師に診断確定された場合、特定疾病保障定期保険特約から受け取る保険金の額は300万円となります。この保険金を受け取った後であっても、Ａさんが不慮の事故で180日以内に亡くなった場合に一時金として、受け取れる死亡保険金の額は4,500万円です」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "3-2.png"
	},
	{
		"id": 80,
		"shikenId": 1,
		"parentNo": 1,
		"no": 5,
		"groupId": 2,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "次に、Ｍさんは、生命保険の見直しについて説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
		"choice1": "「現在加入している定期保険の契約変更を行うことで保険金額を減額させ、その分を必要性の高い介護保険などの保険料に充てることも検討事項の１つです」",
		"choice2": "「現在加入している生命保険を払済終身保険に変更した場合、死亡保険金額は減少しますが、現在付加されている入院特約は残り、月々の保険料負担は軽減されます」",
		"choice3": "「介護保障を検討する際には、公的介護保険等の社会保障制度と連動しているものか、保険金の支払事由が保険会社独自のものか、保険金額等どのような場合に保険金や給付金が支払われるか、加入前に確認しておきましょう」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "3-2.png"
	},
	{
		"id": 81,
		"shikenId": 1,
		"parentNo": 1,
		"no": 6,
		"groupId": 2,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "最後に、Ｍさんは、全国健康保険協会管掌健康保険の高額療養費制度について説明した。Ｍさんが、Ａさんに対して説明した次の記述のうち最も適切なものはどれか。",
		"choice1": "「Ａさんに係る医療費の一部負担金の割合は、原則として１割となります」",
		"choice2": "「同一年内に、医療機関等に支払った医療費の一部負担金等の額が自己負担限度額を超えた場合、所定の手続により、自己負担限度額を超えた額が高額療養費として支給されます」",
		"choice3": "「一部負担金等の合計には、差額ベッド代、入院時の食事代、先進医療に係る費用等は含まれず、70歳未満の者の場合、原則として、医療機関ごとに、入院・外来、医科・歯科別に一部負担金等が21,000円以上のものが計算対象となります」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 4,
		"image": "3-2.png"
	},
	{
		"id": 82,
		"shikenId": 1,
		"parentNo": 1,
		"no": 7,
		"groupId": 3,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "仮に、将来Ｘ社がＢさんに役員退職金7,000万円を支給した場合、Ｂさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ｂさんの役員在任期間（勤続年数）を40年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
		"choice1": "1,400万円",
		"choice2": "2,200万円",
		"choice3": "2,400万円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "3-3.png"
	},
	{
		"id": 83,
		"shikenId": 1,
		"parentNo": 1,
		"no": 8,
		"groupId": 3,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｘ社が加入していた生命保険から死亡保険金を受け取った場合におけるＸ社の経理処理（仕訳）として、次のうち最も適切なものはどれか。なお、当該保険契約からの配当については考慮しないものとする。",
		"choice1": "<img src='/img/82_1.png' width='400'>",
		"choice2": "<img src='/img/82_2.png' width='400'>",
		"choice3": "<img src='/img/82_3.png' width='400'>",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 4,
		"image": "3-3.png"
	},
	{
		"id": 84,
		"shikenId": 1,
		"parentNo": 1,
		"no": 9,
		"groupId": 3,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｍさんは、長男Ｂさんに提案している終身保険の特徴について説明した。Ｍさんの長男Ｂさんに対する説明として、次のうち最も不適切なものはどれか。",
		"choice1": "「当該終身保険は、保険料払込期間における解約返戻金額を抑えることで、低解約返戻金型ではない終身保険と比較して保険料が割安となっています」",
		"choice2": "「Ｂさんが所定の高度障害状態になった場合、高度障害保険金6,000万円がＸ社に支払われます。さらに、その後Ｂさんが死亡した場合には、死亡保険金6,000万円がＸ社に支払われます」",
		"choice3": "「急な資金需要の発生により、Ｘ社が契約者貸付制度を利用した場合、借入金として経理処理します」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "3-3.png"
	},
	{
		"id": 85,
		"shikenId": 1,
		"parentNo": 1,
		"no": 10,
		"groupId": 4,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "所得税の青色申告に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <div class='b-box'>・事業所得に係る取引を正規の簿記の原則に従い記帳し、その記帳に基づいて作成した貸借対照表、損益計算書その他の計算明細書を添付した確定申告書を法定申告期限内に提出することにより、事業所得の金額の計算上、青色申告特別控除として最高（ ① ）万円を控除することができます。<br> なお、従前の要件に加えて、e-Taxによる申告（電子申告）または電子帳簿保存を行うことで、（ ② ）万円の青色申告特別控除の適用を受けることができます。<br> ・青色申告者が適用を受けられる税務上の特典として、青色申告特別控除の適用、青色事業専従者給与の必要経費算入、翌年以後（ ③ ）年間の純損失の繰越控除、純損失の繰戻還付などがあります。</div>",
		"choice1": "① 10 ② 55 ③ ５",
		"choice2": "① 55 ② 65 ③ ３",
		"choice3": "① 55 ② 65 ③ ５",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "3-4.png"
	},
	{
		"id": 86,
		"shikenId": 1,
		"parentNo": 1,
		"no": 11,
		"groupId": 4,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <div class='b-box'>ⅰ）「Ａさんが解約した一時払個人変額年金保険（確定年金）解約返戻金は、税務上、（ ① ）として所得税の課税対象になります」<br> ⅱ）「妻Ｂさんが青色事業専従者として給与の支払いを受けている場合、Ａさんは、妻Ｂさんについて配偶者控除の適用を受けることが（ ② ）」<br> ⅲ）「Ａさんが適用を受けることができる長男Ｃさんに係る扶養控除の額は、（ ③ ）万円です」</div>",
		"choice1": "① 譲渡所得 ② できません ③ 38",
		"choice2": "① 雑所得 ② できます ③ 38",
		"choice3": "① 一時所得 ② できません ③ 63",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "3-4.png"
	},
	{
		"id": 87,
		"shikenId": 1,
		"parentNo": 1,
		"no": 12,
		"groupId": 4,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの2024年分の総所得金額は、次のうちどれか。",
		"choice1": "600万円",
		"choice2": "625万円",
		"choice3": "700万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "3-4.png"
	},
	{
		"id": 88,
		"shikenId": 1,
		"parentNo": 1,
		"no": 13,
		"groupId": 5,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの相続に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <br> <div class='b-box'>・Ａさんの相続における遺産に係る基礎控除額は、（ ① ）万円である。<br> ・相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から（ ② ）以内に提出しなければならない。<br> ・長男Ｃさんが受け取った死亡保険金は、みなし相続財産として相続税の課税対象となるが、死亡保険金の非課税金額の規定の適用を受けることで、相続税の課税価格に算入される金額は（ ③ ）万円となる。</div>",
		"choice1": "① 4,800 ② 10カ月 ③ 2,500",
		"choice2": "① 4,800 ② 12カ月 ③ 2,500",
		"choice3": "① 8,000 ② 12カ月 ③ 2,000",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "3-5.png"
	},
	{
		"id": 89,
		"shikenId": 1,
		"parentNo": 1,
		"no": 14,
		"groupId": 5,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの相続に関する次の記述うち、最も不適切なものはどれか。",
		"choice1": "妻Ｂさんが自宅の敷地を相続により取得し、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けた場合、自宅の敷地（相続税評価額9,000万円）について、相続税の課税価格に算入すべき価額を1,800万円とすることができる。",
		"choice2": "自筆証書遺言は、所定の手続により、法務局（遺言書保管所）に保管することができる。法務局（遺言書保管所）に保管された自筆証書遺言は、相続開始時、家庭裁判所の検認が不要となる。",
		"choice3": "妻Ｂさんが「配偶者に対する相続税額の軽減」の適用を受けるためには、Ａさんの相続開始時において、Ａさんとの婚姻期間が10年以上でなければならない。",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 4,
		"image": "3-5.png"
	},
	{
		"id": 90,
		"shikenId": 1,
		"parentNo": 1,
		"no": 15,
		"groupId": 5,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの相続に係る課税遺産総額（ 「課税価格の合計額－遺産に係る基礎控除額」 ）を２億4,000万円と仮定した場合の相続税の総額は、次のうちどれか。",
		"choice1": "1,200万円",
		"choice2": "5,300万円",
		"choice3": "7,400万円<div class='pt-3'>        <img src='/img/88_2.png' width='420'/>      </div>",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "3-5.png"
	},
	{
		"id": 91,
		"shikenId": 1,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 4,
		"inputType": 4,
		"question1": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を遵守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も適切なものはどれか。",
		"question2": "",
		"choice1": "投資助言・代理業の登録をしていないＦＰが、顧客からの質問を受け、ＮＩＳＡ（少額投資非課税制度）の仕組みと特徴について説明した。",
		"choice2": "税理士資格を有していないＦＰが、無料相談会において、相談者の持参した資料に基づき、相談者が納付すべき相続税の具体的な税額計算を行った。",
		"choice3": "弁護士資格を有しないＦＰが、遺産分割で争っている顧客の依頼を受け、法律相談に基づく和解交渉を行い報酬を得た。",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 92,
		"shikenId": 1,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 4,
		"inputType": 4,
		"question1": "下記は、会社員の田中さんの家庭のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表に関する次の記述のうち、最も適切なものはどれか。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用することとし、計算結果は万円未満を四捨五入することとする。<br><img src=\"/img/question/92_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "空欄（ア）に入る数値とその求め方：「520×(1＋0.01)<sup>2</sup>＝<u>530</u>」",
		"choice2": "空欄（イ）に入る数値とその求め方：「696－742＝<u>▲46</u>」",
		"choice3": "空欄（ウ）に入る数値とその求め方：「(520－102)×(1＋0.01)＝<u>422</u>」",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 93,
		"shikenId": 1,
		"parentNo": 1,
		"no": 3,
		"groupId": 2,
		"answerType": 4,
		"inputType": 4,
		"question1": "下表の空欄（ア）に当てはまる金額として、正しいものはどれか。なお、〈資料〉に記載のあるデータに基づいて解答するものとする。<br><br>〈バランスシート〉<br><img src=\"/img/question/103_2.png\" class=\"question-img\" /><br><br>〈資料〉<br><img src=\"/img/question/106_1.png\" class=\"question-img\" /><br><br>［負債残高］<br> 住宅ローン（自宅） ：3,500万円（団体信用生命保険付）<br> 自動車ローン（自家用）： 120万円<br> <br> 〈その他〉<br> 上記以外については、特に指定のない限り一切考慮しないこととする。 ",
		"question2": "",
		"choice1": "2,080（万円）",
		"choice2": "3,620（万円）",
		"choice3": "5,700（万円）",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 94,
		"shikenId": 1,
		"parentNo": 1,
		"no": 4,
		"groupId": 2,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "会社員の榊治夫さんは、今後15年間で毎年36万円ずつ積立貯蓄をして、老後の資金準備をしたいと考えている。積立期間中に年利2.0％で複利運用できるものとした場合、15年後の積立金額として、正しいものはどれか。<br> なお、下記〈資料〉の3つの係数の中から最も適切な係数を選択して計算し、解答に当たっては、万円未満を四捨五入することとする。また、税金や記載のない事項については一切考慮しないこととする。<br> <br><img src=\"/img/question/105_2.png\" class=\"question-img\" />",
		"choice1": "623万円",
		"choice2": "463万円",
		"choice3": "48万円",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 95,
		"shikenId": 1,
		"parentNo": 1,
		"no": 5,
		"groupId": 2,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "会社員の葛西真一さんは、会社の定期健康診断で異常を指摘され、2024年4月に2週間ほど入院をして治療を受けた。その際の病院への支払いが高額であったため、健康保険の高額療養費制度を利用した。真一さんの2024年4月の保険診療に係る総医療費が60万円であった場合、高額療養費制度により払戻しを受けることができる金額として、正しいものはどれか。なお、真一さんは全国健康保険協会管掌健康保険（協会けんぽ）の被保険者で、標準報酬月額は「35万円」である。また、2024年4月に支払った医療費はこの入院に係るもののみであり、今回の入院について健康保険限度額適用認定証は提示していないものとする。<br> <br><img src=\"/img/question/106_2.png\" class=\"question-img\" />",
		"choice1": "83,430円",
		"choice2": "96,570円",
		"choice3": "180,000円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 96,
		"shikenId": 1,
		"parentNo": 1,
		"no": 6,
		"groupId": 3,
		"answerType": 4,
		"inputType": 4,
		"question1": "下記〈資料〉は、ＴＹ銀行の顧客配布用・投資信託ガイドの「投資信託に関するリスク」のページの一部（抜粋）である。〈資料〉に関する次の記述のうち、最も適切なものはどれか。<br><br>〈資料〉<br><img src=\"/img/question/93_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "空欄（ ア ）に入る語句は、「信用リスク」である。",
		"choice2": "空欄（ イ ）に入る語句は、「為替変動リスク」である。",
		"choice3": "空欄（ ウ ）に入る語句は、「流動性リスク」である。",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 97,
		"shikenId": 1,
		"parentNo": 1,
		"no": 7,
		"groupId": 3,
		"answerType": 4,
		"inputType": 4,
		"question1": "個人向け国債に関する下記の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br>〈資料〉<br><img src=\"/img/question/94_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "（ア）1万円 （イ）0.03％ （ウ）2年",
		"choice2": "（ア）1万円 （イ）0.05％ （ウ）1年",
		"choice3": "（ア）5万円 （イ）0.05％ （ウ）2年",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 98,
		"shikenId": 1,
		"parentNo": 1,
		"no": 8,
		"groupId": 4,
		"answerType": 4,
		"inputType": 4,
		"question1": "下記〈資料〉に関する次の記述の空欄（ア）、（イ）にあてはまる数値または語句の組み合わせとして、正しいものはどれか。なお、空欄（ア）の解答に当たっては、小数点以下第3位を四捨五入すること。<br><br>〈資料〉<br><img src=\"/img/question/95_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "（ア）1.40 （イ）ＫＹ",
		"choice2": "（ア）1.60 （イ）ＫＸ",
		"choice3": "（ア）1.60 （イ）ＫＹ",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 99,
		"shikenId": 1,
		"parentNo": 1,
		"no": 9,
		"groupId": 4,
		"answerType": 4,
		"inputType": 4,
		"question1": "建築基準法に基づき、下記〈資料〉の土地に建築物を建築する場合、建築面積の最高限度（ア）と延べ面積（床面積の合計）の最高限度（イ）の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><br>〈資料〉<br><img src=\"/img/question/96_2.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "（ア）180㎡ （イ）480㎡",
		"choice2": "（ア）180㎡ （イ）600㎡",
		"choice3": "（ア）300㎡ （イ）600㎡",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 100,
		"shikenId": 1,
		"parentNo": 1,
		"no": 10,
		"groupId": 4,
		"answerType": 4,
		"inputType": 4,
		"question1": "定期借地権に関する次の記述のうち、最も適切なものはどれか。",
		"question2": "",
		"choice1": "一般定期借地権の存続期間は50年以上であり、契約方法には制限がない。",
		"choice2": "事業用定期借地権の対象は、主として事業の用に供する建物（居住用建物を含む）であり、契約方法は公正証書でしなければならない。",
		"choice3": "建物譲渡特約付借地権の存続期間は30年以上であり、契約方法には制限がない。",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 101,
		"shikenId": 1,
		"parentNo": 1,
		"no": 11,
		"groupId": 5,
		"answerType": 4,
		"inputType": 4,
		"question1": "土地の登記記録において、権利部のうちの甲区（ア）、乙区（イ）に記録される事項の組み合わせとして、誤っているものはどれか。",
		"question2": "",
		"choice1": "（ア）抵当権設定登記（イ）所有権保存登記",
		"choice2": "（ア）所有権移転登記（イ）抵当権設定登記",
		"choice3": "（ア）所有権移転登記（イ）地上権設定登記",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 102,
		"shikenId": 1,
		"parentNo": 1,
		"no": 12,
		"groupId": 5,
		"answerType": 4,
		"inputType": 4,
		"question1": "下記の〈資料〉に基づき、石川雄一郎さんが加入している生命保険の保障内容に関する次の記述の空欄（ア）、（イ）にあてはまる金額の組合せとして、正しいものはどれか。なお、保険契約は有効に継続しているものとし、石川雄一郎さんはこれまでに下記の〈資料〉の保険から保険金及び給付金を一度も受け取っていないものとし、各々の記述は独立した問題であり、相互に影響を与えないものとする。<br><br><img src=\"/img/question/96_1.png\" class=\"question-img\" /><br><br>石川雄一郎さんが、2024年中に脳卒中によって死亡した場合、保険会社から死亡保険金（　ア　）が支払われる。また、年間（　イ　）の年金が５年間支払われる。",
		"question2": "",
		"choice1": "（ア）1,700万円 （イ）300万円",
		"choice2": "（ア）2,000万円 （イ）500万円",
		"choice3": "（ア）2,000万円 （イ）300万円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 103,
		"shikenId": 1,
		"parentNo": 1,
		"no": 13,
		"groupId": 6,
		"answerType": 4,
		"inputType": 4,
		"question1": "下記の（ア）～（ウ）のイメージ図は、定期保険、終身保険、養老保険のいずれかを表したものである。下記のイメージ図に関する次の記述のうち、最も適切なものはどれか。<br><img src=\"/img/question/99_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "（ア）は「終身保険」に関するイメージ図である。",
		"choice2": "（イ）は「定期保険」に関するイメージ図である。",
		"choice3": "（ウ）は「養老保険」に関するイメージ図である。",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 104,
		"shikenId": 1,
		"parentNo": 1,
		"no": 14,
		"groupId": 6,
		"answerType": 4,
		"inputType": 4,
		"question1": "損害保険の種類と事故の内容に関する次の記述のうち、最も適切なものはどれか。",
		"question2": "",
		"choice1": "国内旅行傷害保険は、国内旅行中に発生した地震や津波によるケガも対象とする。",
		"choice2": "普通傷害保険は、草野球の試合中にボールが右指を直撃し、被保険者が骨折した場合の怪我の補償を対象とする。",
		"choice3": "個人賠償責任保険（特約）は、被保険者が仕事で自転車を使用中に、誤って歩行者と接触し、ケガをさせた場合の損害賠償責任の補償を対象とする。",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 105,
		"shikenId": 1,
		"parentNo": 1,
		"no": 15,
		"groupId": 6,
		"answerType": 4,
		"inputType": 4,
		"question1": "Ｋ株式会社の会社員である竹市雄二さんが2024年中に支払った医療費等が下記〈資料〉のとおりである場合、竹市さんの2024年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、竹市さんの所得は給与所得600万円のみであり、妻は竹市さんと生計を一にしている。また、医療費控除の金額が最も大きくなるよう計算すること。<br><br><img src=\"/img/question/100_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "290,000円",
		"choice2": "390,000円",
		"choice3": "450,000円",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 106,
		"shikenId": 1,
		"parentNo": 1,
		"no": 16,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "会社員の山川さんは、2024年中に勤務先を定年退職した。山川さんの退職に係るデータが下記〈資料〉のとおりである場合、山川さんの所得税に係る退職所得の金額として、正しいものはどれか。<br><br><img src=\"/img/question/101_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "1,280万円",
		"choice2": "1,220万円",
		"choice3": "640万円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 107,
		"shikenId": 1,
		"parentNo": 1,
		"no": 17,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "給与所得者である杉田さんは、２０２４年中に認定住宅を購入し、直ちに居住を開始した。杉田さんは、住宅借入金等特別控除（以下「住宅ローン控除」という）の適用を受けたいと考えており、ＦＰで税理士でもある村瀬さんに相談をした。村瀬さんの住宅ローン控除に関する次の説明のうち、最も適切なものはどれか。",
		"question2": "",
		"choice1": "「住宅ローン控除の適用対象となる住宅の床面積は、７０㎡以上とされています。」",
		"choice2": "「住宅ローン控除の控除額は、『住宅借入金等の年末残高等×１％』で計算されます。」",
		"choice3": "「住宅ローン控除は、その年分の合計所得金額が2,０００万円以下でなければ適用を受けることができません。」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 108,
		"shikenId": 1,
		"parentNo": 1,
		"no": 18,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "2024年4月10日に相続が開始された被相続人の〈親族関係図〉が下記のとおりである場合、民法上の相続人および法定相続分として、正しいものはどれか。なお、記載のない条件については、一切考慮しないこととする。<br><br><img src=\"/img/question/102_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "配偶者 1/2 長男 1/4 長女 1/4",
		"choice2": "配偶者 2/3 母 1/3",
		"choice3": "配偶者 1/2 長男 1/2",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 109,
		"shikenId": 1,
		"parentNo": 1,
		"no": 19,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "村西誠一さん（30歳）が2024年中に贈与を受けた財産の価額と贈与者は以下のとおりである。誠一さんの2024年分の贈与税額として、正しいものはどれか。なお、2024年中において、誠一さんはこれ以外の財産の贈与を受けておらず、相続時精算課税制度は選択していないものとする。<br><br><img src=\"/img/question/103_1.png\" class=\"question-img\" /><br><br>〈贈与税の速算表〉<br>（イ）18歳以上の者が直系尊属から贈与を受けた財産の場合<br><img src=\"/img/question/104_1.png\" class=\"question-img\" /><br><br>（ロ）上記（イ）以外の場合<br><img src=\"/img/question/105_1.png\" class=\"question-img\" />",
		"question2": "",
		"choice1": "700万円",
		"choice2": "110万円",
		"choice3": "88万円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 110,
		"shikenId": 1,
		"parentNo": 1,
		"no": 20,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "長谷川信子さんは、夫から居住用不動産の贈与を受けた。信子さんは、この居住用不動産の贈与について、贈与税の配偶者控除の適用を受けることを検討しており、ＦＰで税理士でもある八代さんに相談をした。この相談に対する八代さんの回答について次の記述のうち、最も不適切なものはどれか。",
		"question2": "",
		"choice1": "「贈与税の配偶者控除の対象となる贈与は、居住用不動産の贈与または居住用不動産を取得するための金銭の贈与です。」",
		"choice2": "「贈与税の配偶者控除を受ける場合、基礎控除110万円とは別に最高2,500万円の控除を受けることができます。」",
		"choice3": "「贈与があった日において、配偶者との婚姻期間が20年以上あること等の所定の要件を満たす必要があります。」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 201,
		"shikenId": 2,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "司法書士資格を有しないファイナンシャル・プランナーが顧客の依頼により任意後見契約を締結し、任意後見受任者となることは司法書士法に抵触しない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 202,
		"shikenId": 2,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "確定拠出年金（個人型）の老齢給付金を一時金で受け取った場合、雑所得として所得税の課税対象となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 203,
		"shikenId": 2,
		"parentNo": 1,
		"no": 3,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "雇用保険から基本手当が支給される場合、原則として、離職の日の翌日から１年間に受給しなければならない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 204,
		"shikenId": 2,
		"parentNo": 1,
		"no": 4,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "フラット35を利用する場合、年収に対する返済額の割合が定められており、要件を満たせば融資が受けられる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 205,
		"shikenId": 2,
		"parentNo": 1,
		"no": 5,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "60歳台後半の厚生年金被保険者に支給される老齢厚生年金は、総報酬月額相当額と基本月額の合計が40万円を超える場合、年金額の一部または全部が支給停止となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 206,
		"shikenId": 2,
		"parentNo": 1,
		"no": 6,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "収入保障保険から支払われる死亡保険金を一時金で受取る場合の金額は、年金形式で受取る金額よりも少なくなる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 207,
		"shikenId": 2,
		"parentNo": 1,
		"no": 7,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "終身保険では、保険料の払込期間が満了すると、保障の内容を老後の年金や介護に変更することができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 208,
		"shikenId": 2,
		"parentNo": 1,
		"no": 8,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "学資保険に出生前加入特則を付加することにより、子が出生する前から保険に加入することができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 209,
		"shikenId": 2,
		"parentNo": 1,
		"no": 9,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "リビング・ニーズ特約は、被保険者の余命が１年以内と判断された場合に、死亡保険金の一部または全部を受け取ることができる特約である。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 210,
		"shikenId": 2,
		"parentNo": 1,
		"no": 10,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "少額短期保険業者と締結した保険契約は、生命保険契約者保護機構および損害保険契約者保護機構による補償の対象とならない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 211,
		"shikenId": 2,
		"parentNo": 1,
		"no": 11,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "マネーストック統計とは、個人、一般法人、地方公共団体が保有する通貨量の残高を集計したものである。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 212,
		"shikenId": 2,
		"parentNo": 1,
		"no": 12,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "外貨建金融商品を円で購入する際の為替手数料は、取扱金融機関によって異なることがある。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 213,
		"shikenId": 2,
		"parentNo": 1,
		"no": 13,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "債券の信用格付けがダブルＢ以下である場合、一般的に投資不適格債と判断される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 214,
		"shikenId": 2,
		"parentNo": 1,
		"no": 14,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "ゆうちょ銀行の預入限度額は、通常貯金と定期性貯金を合わせて１人2,600万円までとなっている。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 215,
		"shikenId": 2,
		"parentNo": 1,
		"no": 15,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "消費者契約法における保護の対象は、個人が締結した契約となるため、事業のために締結した契約は対象外となっている。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 216,
		"shikenId": 2,
		"parentNo": 1,
		"no": 16,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "給与所得者であっても年収が2,000万円を超える場合、確定申告をする必要がある。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 217,
		"shikenId": 2,
		"parentNo": 1,
		"no": 17,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "非永住者以外である居住者は、国内源泉所得のみについて所得税が課税される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 218,
		"shikenId": 2,
		"parentNo": 1,
		"no": 18,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "所得税において給与所得を計算する場合、勤務先から支払われる通勤手当は月額15万円を限度に非課税とされる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 219,
		"shikenId": 2,
		"parentNo": 1,
		"no": 19,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "公的年金等に係る雑所得の金額は、「収入金額－公的年金等控除額－50万円」の算式により計算される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 220,
		"shikenId": 2,
		"parentNo": 1,
		"no": 20,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "「ふるさと納税ワンストップ特例制度」の適用を受けるためには、適用を受けようとする年の寄付金の合計額が10万円以下でなければならない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 221,
		"shikenId": 2,
		"parentNo": 1,
		"no": 21,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "土地の賃借権に関する登記がない場合でも、借地上の建物に関する登記があれば第三者に対して土地の賃借権を対抗することができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 222,
		"shikenId": 2,
		"parentNo": 1,
		"no": 22,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "不動産の売買契約を解除する場合、相手方が契約の履行に着手していなければ、買主は手付金の放棄、売主はその同額を現実に提供することにより契約を解除することができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 223,
		"shikenId": 2,
		"parentNo": 1,
		"no": 23,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "相続により不動産を取得した場合、不動産取得税は課税されない。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 224,
		"shikenId": 2,
		"parentNo": 1,
		"no": 24,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "不動産の仮登記を済ませておけば、順位保全の効力だけでなく、第三者への対抗力も認められる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 225,
		"shikenId": 2,
		"parentNo": 1,
		"no": 25,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "定期建物賃貸借契約（定期借家権）の場合、契約期間を１年以上としなければ、契約は無効となる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 226,
		"shikenId": 2,
		"parentNo": 1,
		"no": 26,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "特別養子縁組によって養子になった場合、実父母との法律上の親族関係は終了する。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 227,
		"shikenId": 2,
		"parentNo": 1,
		"no": 27,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "成年後見制度には法定後見制度と任意後見制度があるが、任意後見制度では本人の判断能力の程度などに応じて「後見」 「保佐」 「補助」の３類型に区分して支援される。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 228,
		"shikenId": 2,
		"parentNo": 1,
		"no": 28,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "使用貸借によって父が所有する土地を子が無償で借り、その土地の上に建物を建築した場合、父から子へ借地権の贈与があったものとみなされる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 229,
		"shikenId": 2,
		"parentNo": 1,
		"no": 29,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "民法における非嫡出子の法定相続分は、嫡出子の２分の１とされている。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 230,
		"shikenId": 2,
		"parentNo": 1,
		"no": 30,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
		"question2": "贈与契約を口頭によって締結した場合、まだ履行していない部分に限り取り消すことができる。",
		"choice1": "○",
		"choice2": "✕",
		"choice3": "",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 231,
		"shikenId": 2,
		"parentNo": 2,
		"no": 31,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "国民健康保険や健康保険の被保険者（障害状態にない）は、（　）になると後期高齢者医療制度の被保険者となる。",
		"choice1": "1）65歳",
		"choice2": "2）70歳",
		"choice3": "3）75歳",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 232,
		"shikenId": 2,
		"parentNo": 2,
		"no": 32,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "元金1,000,000円を利率２％で複利運用しながら20年間にわたって毎年均等に取り崩して受取る場合、毎年の受取金額を下記の〈資料〉を利用して算出すると（　）となる。<br> 〈資料〉利率（年率）２％・期間20年の各種係数<br><img src='/img/234.png' style='width:550px'/>",
		"choice1": "1）14,840円",
		"choice2": "2）61,160円",
		"choice3": "3）82,640円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 233,
		"shikenId": 2,
		"parentNo": 2,
		"no": 33,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "日本学生支援機構が取り扱う奨学金（貸与型）には、（ ① ）の第一種奨学金と（ ② ）の第二種奨学金がある。",
		"choice1": "1）① 無利息 ② 利息付",
		"choice2": "2）① 利息付 ② 無利息",
		"choice3": "3）① 返済不要 ② 無利息",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 234,
		"shikenId": 2,
		"parentNo": 2,
		"no": 34,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "雇用保険から支給される教育訓練給付金のうち、一般教育訓練を受講したことにより受取る給付金の額は、受講費用の（ ① ）で、上限額は（ ② ）となっている。",
		"choice1": "1）① 10％ ② ５万円",
		"choice2": "2）① 20％ ② 10万円",
		"choice3": "3）① 30％ ② 15万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 235,
		"shikenId": 2,
		"parentNo": 2,
		"no": 35,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "所得税の計算において、国民年金基金に対して拠出した掛金は、（　）として所得控除の対象となる。",
		"choice1": "1）小規模共済等掛金控除",
		"choice2": "2）生命保険料控除",
		"choice3": "3）社会保険料控除",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 236,
		"shikenId": 2,
		"parentNo": 2,
		"no": 36,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "ソルベンシー・マージン比率とは、保険会社の保険金に対する支払い余力をあらわしたものであり、この比率が（　）以上であれば健全性について１つの基準を満たしているといえる。",
		"choice1": "1）50％",
		"choice2": "2）100％",
		"choice3": "3）200％",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 237,
		"shikenId": 2,
		"parentNo": 2,
		"no": 37,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "一般にがん保険では入院給付日数が無制限となっているが、責任開始日の前に約（　）の免責期間が設けられている。",
		"choice1": "1）１カ月",
		"choice2": "2）３カ月",
		"choice3": "3）６カ月",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 238,
		"shikenId": 2,
		"parentNo": 2,
		"no": 38,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "ホテルのクロークで預かっていた顧客の荷物が盗難に遭ったことにより負担する法律上の賠償責任に備えるには（　）に加入する必要がある。",
		"choice1": "1）受託者賠償責任保険",
		"choice2": "2）施設所有者賠償責任保険",
		"choice3": "3）生産物賠償責任保険",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 239,
		"shikenId": 2,
		"parentNo": 2,
		"no": 39,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "民法および失火の責任に関する法律（失火責任法）において、借家人が軽過失により火事を起こし、借家と隣家を焼失させた場合、借家の家主に対して損害賠償責任を（ ① ）。また、隣家の所有者に対して損害賠償責任を（ ② ）。",
		"choice1": "1）① 負う ② 負わない",
		"choice2": "2）① 負う ② 負う",
		"choice3": "3）① 負わない ② 負う",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 240,
		"shikenId": 2,
		"parentNo": 2,
		"no": 40,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "被保険者の死亡後、契約で定めた期間分の年金が遺族に支払われる保険を（　）という。",
		"choice1": "1）所得補償保険",
		"choice2": "2）収入（生活）保障保険",
		"choice3": "3）特定疾病保障保険",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 241,
		"shikenId": 2,
		"parentNo": 2,
		"no": 41,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "個人向け国債は、変動金利の商品である（ ① ）、固定金利の商品である（ ② ）の３種類がある。変動金利商品の適用金利は半年に１度見直しとなっている。",
		"choice1": "1）① 変動３年・変動５年 ② 固定１年",
		"choice2": "2）① 変動５年 ② 固定１年・固定３年",
		"choice3": "3）① 変動10年 ② 固定３年・固定５年",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 242,
		"shikenId": 2,
		"parentNo": 2,
		"no": 42,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "デリバティブ取引などを利用して、ベンチマークする指数に対して何倍もの投資成果を得ることを目的として運用され、相場の（ ① ）局面で利益が得られるように設定される投資信託を（ ② ）という。",
		"choice1": "1）① 下落 ② ベア型ファンド",
		"choice2": "2）① 下落 ② ブル型ファンド",
		"choice3": "3）① 上昇 ② ベア型ファンド",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 243,
		"shikenId": 2,
		"parentNo": 2,
		"no": 43,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "外貨預金に預入をするため、円を外貨に換える場合は、（ ① ）レートを用いて計算を行う。また、払出の際に外貨を円に換える場合は、（ ② ）レートを用いて計算を行う。",
		"choice1": "1）① ＴＴＢ ② ＴＴＳ",
		"choice2": "2）① ＴＴＭ ② ＴＴＳ",
		"choice3": "3）① ＴＴＳ ② ＴＴＢ",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 244,
		"shikenId": 2,
		"parentNo": 2,
		"no": 44,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "投資信託の銘柄選択方法である（ ① ）とは、マクロ経済の動向を分析して投資対象を選択する方法である。一方、（ ② ）とは、個別企業へのリサーチなどによる情報を基に投資魅力の高い銘柄を選択する方法である。",
		"choice1": "1）① インデックス・アプローチ ② アクティブ・アプローチ",
		"choice2": "2）① トップダウン・アプローチ ② ボトムアップ・アプローチ",
		"choice3": "3）① グロース・アプローチ ② バリュー・アプローチ",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 245,
		"shikenId": 2,
		"parentNo": 2,
		"no": 45,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "2,000,000円を年利率３％（１年複利）で３年間運用した場合の元利合計金額は、手数料や税金等を考慮しない場合、（　）となる。",
		"choice1": "1）2,083,654円",
		"choice2": "2）2,121,800円",
		"choice3": "3）2,185,454円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 246,
		"shikenId": 2,
		"parentNo": 2,
		"no": 46,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "2024年分の所得税について扶養控除の適用を受ける場合、配偶者以外の同一生計親族で年齢が（ ① ）の者が対象となり、原則38万円の控除が受けられる。また、（ ② ）の者は、特定扶養親族となり、63万円の控除が受けられる。",
		"choice1": "1）① 12歳以上 ② 16歳以上22歳未満",
		"choice2": "2）① 16歳以上 ② 19歳以上23歳未満",
		"choice3": "3）① 18歳以上 ② 20歳以上24歳未満",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 247,
		"shikenId": 2,
		"parentNo": 2,
		"no": 47,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "所得税の計算において医療費控除の計算をする際、（　）は医療費控除の対象とならない。",
		"choice1": "1）治療のための入院中の食事代",
		"choice2": "2）薬局で購入した風邪薬の購入費",
		"choice3": "3）コンタクトレンズや近視、乱視によるメガネの購入費用",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 248,
		"shikenId": 2,
		"parentNo": 2,
		"no": 48,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "配当所得を確定申告する場合、（ ① ）にて確定申告をすると配当控除の適用を受けることができる。<br> 配当控除の金額は、配当所得の（ ② ）で計算した金額が控除額となる。<br> また、課税総所得金額等が、1,000万円を超える場合、その超える部分については、配当所得の５％で計算した金額が控除額となる。",
		"choice1": "1）① 総合課税 ② 10％",
		"choice2": "2）① 申告分離課税 ② 15％",
		"choice3": "3）① 源泉分離課税 ② 20.315％",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 249,
		"shikenId": 2,
		"parentNo": 2,
		"no": 49,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "青色申告者である者に損益通算しても控除しきれない損失の金額（純損失の金額）が生じた場合、所定の要件を満たすことで、その損失の金額を翌年以後（　）にわたって繰り越すことができる。",
		"choice1": "1）２年間",
		"choice2": "2）３年間",
		"choice3": "3）４年間",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 250,
		"shikenId": 2,
		"parentNo": 2,
		"no": 50,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "損失が生じた場合、他の所得と損益通算できる所得は、（　）の４つの所得に限られている。",
		"choice1": "1）不動産所得・事業所得・山林所得・一時所得",
		"choice2": "2）不動産所得・一時所得・雑所得・譲渡所得",
		"choice3": "3）不動産所得・事業所得・山林所得・譲渡所得",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 251,
		"shikenId": 2,
		"parentNo": 2,
		"no": 51,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "取得費が不明である土地を譲渡した場合、譲渡所得の計算は概算取得費を用いて計算するが、概算取得費は譲渡対価に（　）を乗じた金額となる。",
		"choice1": "1）５％",
		"choice2": "2）15％",
		"choice3": "3）20％",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 252,
		"shikenId": 2,
		"parentNo": 2,
		"no": 52,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "固定資産税における課税標準の特例では、小規模住宅用地（住宅１戸につき200㎡以下の部分）については、課税標準が（　）となる。",
		"choice1": "1）２分の１",
		"choice2": "2）３分の１",
		"choice3": "3）６分の１",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 253,
		"shikenId": 2,
		"parentNo": 2,
		"no": 53,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "道路の幅員が４ｍ未満で特定行政庁から指定を受けている道路（２項道路）は、原則として道路の中心線から水平距離（ ① ）の線が道路の境界線とみなされる。<br> このセットバック部分は、建蔽率や容積率を計算する際、敷地面積に（ ② ）。",
		"choice1": "1）① 1.5ｍ ② 算入する",
		"choice2": "2）① ２ｍ ② 算入する",
		"choice3": "3）① ２ｍ ② 算入しない",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 254,
		"shikenId": 2,
		"parentNo": 2,
		"no": 54,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "「特定居住用財産の譲渡損失の損益通算及び繰越控除の特例」の適用を受けた場合、損益通算をしても控除しきれなかった譲渡損失の金額は、譲渡した年の翌年以降（　）にわたり繰越控除が受けられる。",
		"choice1": "1）１年間",
		"choice2": "2）３年間",
		"choice3": "3）５年間",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 255,
		"shikenId": 2,
		"parentNo": 2,
		"no": 55,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "農地を宅地に転用する場合、原則、都道府県知事の許可が必要となるが、市街化区域内の一定の農地を宅地に転用する場合については、あらかじめ（　）へ届出をすれば許可は不要となる。",
		"choice1": "1）農業委員会",
		"choice2": "2）市区町村長",
		"choice3": "3）都道府県知事",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 1,
		"image": ""
	},
	{
		"id": 256,
		"shikenId": 2,
		"parentNo": 2,
		"no": 56,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "業務上の死亡により、遺族が雇用主から受ける弔慰金については、被相続人の死亡時における普通給与の（　）に相当する金額までは相続税がかからない。",
		"choice1": "1）１カ月分",
		"choice2": "2）６カ月分",
		"choice3": "3）３年分",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 257,
		"shikenId": 2,
		"parentNo": 2,
		"no": 57,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "遺留分侵害額の請求権は、相続の開始および遺留分を侵害する贈与または遺贈があったことを知った時から（ ① ）、または相続開始の時から（ ② ）を経過すると時効により消滅する。",
		"choice1": "1）① ３カ月間 ② １年間",
		"choice2": "2）① １年間 ② 10年間",
		"choice3": "3）① ３年間 ② ５年間",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 258,
		"shikenId": 2,
		"parentNo": 2,
		"no": 58,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "共同相続人の１人または数人が相続により相続財産の現物を取得し、その者が他の相続人に対して債務を負担する分割の方法を（　）という。",
		"choice1": "1）換価分割",
		"choice2": "2）代償分割",
		"choice3": "3）現物分割",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 259,
		"shikenId": 2,
		"parentNo": 2,
		"no": 59,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "相続の放棄をする者は、相続の開始があったことを知った時から（ ① ）以内に（ ② ）で家庭裁判所に申述する必要がある。",
		"choice1": "1）① １カ月 ② 相続人全員",
		"choice2": "2）① ３カ月 ② １人または数人",
		"choice3": "3）① ６カ月 ② 相続人全員",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 1,
		"image": ""
	},
	{
		"id": 260,
		"shikenId": 2,
		"parentNo": 2,
		"no": 60,
		"groupId": 1,
		"answerType": 1,
		"inputType": 1,
		"question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
		"question2": "公正証書遺言は作成する際に証人の立会が（ ① ）となる。<br> なお、遺言者の相続開始時には家庭裁判所の検認を受ける（ ② ）。",
		"choice1": "1）① 必要 ② 必要がある",
		"choice2": "2）① 不要 ② 必要がある",
		"choice3": "3）① 必要 ② 必要がない",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 1,
		"image": ""
	},
	{
		"id": 261,
		"shikenId": 2,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "現時点（2024年９月11日）においてＡさんが死亡した場合、妻Ｂさんに支給される遺族基礎年金の年金額（2024年度価額）は、次のうちどれか。",
		"choice1": "816,000円＋78,300円＋78,300円＝972,600円",
		"choice2": "816,000円＋234,800円＋78,300円＝1,129,100円",
		"choice3": "816,000円＋234,800円＋234,800円＝1,285,600円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "5-1.png"
	},
	{
		"id": 262,
		"shikenId": 2,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ｍさんは、現時点（2024年９月11日）においてＡさんが死亡した場合に、妻Ｂさんに支給される遺族厚生年金の金額等について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br> <br><div class='b-box'>「遺族厚生年金の額は、原則として、Ａさんの厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の（ ① ）相当額となります。ただし、Ａさんの場合、その計算の基礎となる被保険者期間の月数が（ ② ）月に満たないため、（ ② ）月とみなして年金額が計算されます。<br> また、二男Ｄさんの18歳到達年度の末日が終了し、妻Ｂさんの有する遺族基礎年金の受給権が消滅したときは、妻Ｂさんが65歳に達するまでの間、妻Ｂさんに支給される遺族厚生年金（ ③ ）が加算されます」</div>",
		"choice1": "① ３分の２ ② 240 ③ 中高齢寡婦加算",
		"choice2": "① ４分の３ ② 300 ③ 中高齢寡婦加算",
		"choice3": "① ４分の３ ② 240 ③ 経過的寡婦加算",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "5-1.png"
	},
	{
		"id": 263,
		"shikenId": 2,
		"parentNo": 1,
		"no": 3,
		"groupId": 1,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ｍさんは、公的介護保険（以下、「介護保険」という）について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>「介護保険の被保険者が保険給付を受けるためには、市町村（特別区を含む）から要介護・要支援認定を受ける必要があります。介護保険の被保険者は、（ ① ）以上の第１号被保険者と40歳以上（ ① ）未満の医療保険加入者である第２号被保険者に区分されます。介護保険の第２号被保険者は、（ ② ）要介護状態または要支援状態となった場合に保険給付を受けることができます。<br> 介護保険の第２号被保険者が介護給付を受けた場合、原則として、実際にかかった費用（食費、居住費等を除く）の（ ③ ）を自己負担する必要があります」</div>",
		"choice1": "① 60歳 ② 特定疾病が原因で ③ ２割",
		"choice2": "① 65歳 ② 原因を問わず ③ ２割",
		"choice3": "① 65歳 ② 特定疾病が原因で ③ １割",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "5-1.png"
	},
	{
		"id": 264,
		"shikenId": 2,
		"parentNo": 1,
		"no": 4,
		"groupId": 2,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "はじめに、Ｍさんは、Ｘ社株式の投資指標について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
		"choice1": "「株価の相対的な割高・割安の度合いを判断する指標に、ＰＥＲやＰＢＲがあります。＜Ｘ社に関する資料＞から算出されるＸ社株式のＰＥＲは1.2倍、ＰＢＲは12倍です」",
		"choice2": "「ＰＥＲとＰＢＲは、一般に、どちらも数値が高いほうが株価は割安と判断されますが、何倍程度が妥当であるかを検討する際は、同業他社の数値や過去の傾向と比較するなど、相対的な数値として捉えることが重要です」",
		"choice3": "「株価に対する１株当たりの年間配当金の割合を示す指標を配当利回りといいます。＜Ｘ社に関する資料＞から算出されるＸ社株式の配当利回りは2.5％です」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 4,
		"image": "5-2.png"
	},
	{
		"id": 265,
		"shikenId": 2,
		"parentNo": 1,
		"no": 5,
		"groupId": 2,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "次に、Ｍさんは、Ｙ投資信託の購入に係る費用等について説明した。ＭさんのＡさ んに対する説明として、次のうち最も不適切なものはどれか。",
		"choice1": "「Ｙ投資信託の購入時に手数料はかかりません。新しいNISAの対象となる公募株式投資信託は、購入時手数料がゼロであることが要件の１つとなっています」",
		"choice2": "「運用管理費用（信託報酬）は、投資信託を保有する投資家が間接的に負担する費用です。一般に、Ｙ投資信託のようなインデックス型投資信託は、アクティブ型投資信託よりも運用管理費用（信託報酬）が高い傾向があります」",
		"choice3": "「信託財産留保額は、投資信託の組入資産を売却する際に発生する手数料等を、投資信託を換金する投資家に負担してもらうことを目的として設けられているものですが、Ｙ投資信託では、この費用はかかりません」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "5-2.png"
	},
	{
		"id": 266,
		"shikenId": 2,
		"parentNo": 1,
		"no": 6,
		"groupId": 2,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "最後に、Ｍさんは、「新しいNISA」についてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
		"choice1": "「新しいNISAのつみたて投資枠の対象となる金融商品は、長期の積立・分散投資に適した一定の商品性を有する公募株式投資信託やＥＴＦとされ、上場株式は対象となっていません」",
		"choice2": "「新しいNISAの年間のつみたて投資枠における非課税投資枠は40万円です。購入は累積投資契約に基づく定期かつ継続的な買付けを行う方法に限られており、非課税期間は10年間です」",
		"choice3": "「新しいNISAを利用して購入した公募株式投資信託を解約した際に損失が生じた場合、その損失の金額は、特定口座で保有する上場株式等の譲渡益と通算することができます」",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "5-2.png"
	},
	{
		"id": 267,
		"shikenId": 2,
		"parentNo": 1,
		"no": 7,
		"groupId": 3,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。",
		"choice1": "563万円",
		"choice2": "588万円",
		"choice3": "638万円<br><br>〈資料〉給与所得控除額<br><img src='/img/67_1.png' width='500'>",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 4,
		"image": "5-3.png"
	},
	{
		"id": 268,
		"shikenId": 2,
		"parentNo": 1,
		"no": 8,
		"groupId": 3,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>ⅰ）「Ａさんが適用を受けることができる配偶者控除の額は、（ ① ）万円です」<br> ⅱ）「長女Ｃさんの合計所得金額は（ ② ）万円を超えますので、Ａさんは長女Ｃさんに係る扶養控除の適用を受けることはできません」<br> ⅲ）「Ａさんが適用を受けることができる長男Ｄさんに係る扶養控除の額は、（ ③ ）万円です」</div>",
		"choice1": "① 38 ② 48  ③ 63",
		"choice2": "① 38 ② 103 ③ 48",
		"choice3": "① 48 ② 103 ③ 63",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "5-3.png"
	},
	{
		"id": 269,
		"shikenId": 2,
		"parentNo": 1,
		"no": 9,
		"groupId": 3,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における医療費控除および確定申告に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "「Ａさんが、妻Ｂさんの通院時に自家用車で送迎していた場合、その際にかかったガソリン代や駐車料金は、医療費控除の対象となる医療費の範囲に含まれます」",
		"choice2": "「Ａさんが2024年中に支払った医療費の総額が20万円を超えていない場合、医療費控除額は算出されません」",
		"choice3": "「Ａさんは、医療費控除の適用を受けない場合であっても、総所得金額に算入される一時所得の金額が20万円を超えるため、所得税の確定申告をしなければなりません」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "5-3.png"
	},
	{
		"id": 270,
		"shikenId": 2,
		"parentNo": 1,
		"no": 10,
		"groupId": 4,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
		"choice1": "① 360㎡ ② 1,440㎡",
		"choice2": "① 360㎡ ② 1,200㎡",
		"choice3": "① 400㎡ ② 1,200㎡",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "5-4.png"
	},
	{
		"id": 271,
		"shikenId": 2,
		"parentNo": 1,
		"no": 11,
		"groupId": 4,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "自宅（建物およびその敷地である甲土地）の譲渡に関する以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <br> <div class='b-box'>ⅰ）「仮に、Ａさんがタワーマンションに転居し、その後、居住していない現在の自宅を譲渡した場合に、Ａさんが『居住用財産を譲渡した場合の3,000万円の特別控除の特例』の適用を受けるためには、家屋に自己が居住しなくなった日から（ ① ）を経過する日の属する年の12月31日までの譲渡であること等の要件を満たす必要があります。また、『居住用財産を譲渡した場合の長期譲渡所得の課税の特例』の適用を受けた場合、課税長期譲渡所得金額が（ ② ）以下の部分については、軽減税率が適用されます」<br> ⅱ）「Ａさんが自宅を譲渡し、マンションを購入した場合、譲渡した年の１月１日において譲渡した居住用財産の所有期間が（ ③ ）を超えていること等の要件を満たせば、『特定の居住用財産の買換えの場合の長期譲渡所得の課税の特例』の適用を受けることができます」</div>",
		"choice1": "① ３年 ② １億円  ③ ５年",
		"choice2": "① ５年 ② １億円  ③ 10年",
		"choice3": "① ３年 ② 6,000万円 ③ 10年",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "5-4.png"
	},
	{
		"id": 272,
		"shikenId": 2,
		"parentNo": 1,
		"no": 12,
		"groupId": 4,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "自己建設方式による甲土地の有効活用に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「Ａさんが甲土地に賃貸マンションを建築した場合、相続税の課税価格の計算上、甲土地は貸宅地として評価されます」",
		"choice2": "「甲土地が貸付事業用宅地等に該当すれば、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けることができます。貸付事業用宅地等は、相続税の課税価格の計算上、200㎡までの部分について50％の減額が受けられます」",
		"choice3": "「賃貸マンションを建築することで相続税等の軽減が期待できますが、将来の賃料収入が減少することや、借入金の返済が滞ることなどのリスクを考慮し、実行にあたっては慎重な計画が求められます」",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "5-4.png"
	},
	{
		"id": 273,
		"shikenId": 2,
		"parentNo": 1,
		"no": 13,
		"groupId": 5,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの相続等に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "「遺言により妻Ｂさんおよび長女Ｃさんが相続財産の大半を取得した場合、二女Ｄさんの遺留分を侵害する可能性があります。仮に、遺留分を算定するための財産の価額が３億円である場合、二女Ｄさんの遺留分の金額は7,500万円となります」",
		"choice2": "「死亡保険金を活用した代償分割の方法を考えた場合、契約者（＝保険料負担者）および被保険者をＡさん、死亡保険金受取人を長女Ｃさんとする終身保険に加入することも検討事項の１つとなります」",
		"choice3": "「遺産分割をめぐる争いを防ぐ手段として、公正証書遺言の作成をお勧めします。公正証書遺言は証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がこれを筆記して作成します」",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "5-5.png"
	},
	{
		"id": 274,
		"shikenId": 2,
		"parentNo": 1,
		"no": 14,
		"groupId": 5,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "仮に、Ａさんの相続が現時点（2024年５月23日）で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が２億4,000万円であった場合の相続税の総額は、次のうちどれか。",
		"choice1": "2,200万円",
		"choice2": "5,300万円",
		"choice3": "8,100万円<div class='pt-3'>        <img src='/img/88_2.png' width='420'/>      </div>",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "5-5.png"
	},
	{
		"id": 275,
		"shikenId": 2,
		"parentNo": 1,
		"no": 15,
		"groupId": 5,
		"answerType": 2,
		"inputType": 2,
		"question1": "",
		"question2": "Ａさんの相続に係る小規模宅地等についての相続税の課税価格の計算の特例（以下、「本特例」という）に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "「妻Ｂさんが自宅の敷地を相続により取得し、特定居住用宅地等として本特例の適用を受けた場合、330㎡までの部分について80％の減額が受けられます」",
		"choice2": "「妻Ｂさんが自宅の敷地を相続により取得し、相続税の申告期限までに自宅の敷地を売却した場合、当該敷地は特定居住用宅地等として本特例の適用を受けることができなくなります」",
		"choice3": "「自宅の敷地と賃貸ビルの敷地について、本特例の適用を受けようとする場合、適用対象面積の調整はせず、それぞれの宅地等の適用対象の限度面積まで適用を受けることができます」",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "5-5.png"
	},
	{
		"id": 276,
		"shikenId": 2,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "はじめに、Ｍさんは、老齢基礎年金について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br> <br> 「老齢基礎年金を受給するためには、原則として、国民年金の保険料納付済期間と保険料免除期間を合算した期間が（ ① ）年必要です。Ａさんは、（ ① ）年の受給資格期間を満たしていますので、原則として65歳から老齢基礎年金を受給することができます。<br> Ａさんが希望すれば、60歳以上65歳未満の間に老齢基礎年金の繰上げ支給を請求することができます。ただし、老齢基礎年金の繰上げ支給を請求した場合は、（ ② ）減額された年金が支給されることになります。仮に、Ａさんが60歳０カ月で老齢基礎年金の繰上げ支給を請求した場合の減額率は（ ③ ）％となります」",
		"choice1": "① 10 ② 生涯  ③ 24",
		"choice2": "① 10 ② 75歳まで ③ 30",
		"choice3": "① 25 ② 生涯  ③ 30",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "6-1.png"
	},
	{
		"id": 277,
		"shikenId": 2,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "次に、Ｍさんは、国民年金の付加保険料について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
		"choice1": "「仮に、Ａさんが付加保険料を145月納付し、65歳から老齢基礎年金を受け取る場合、老齢基礎年金の額に付加年金として年額29,000円が上乗せされます」",
		"choice2": "「老齢基礎年金の繰上げ支給を請求した場合でも、付加年金の年金額は減額されません」",
		"choice3": "「国民年金の第１号被保険者は、国民年金基金に加入することができますが、国民年金基金に加入した場合は、付加保険料を納付することができません」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "6-1.png"
	},
	{
		"id": 278,
		"shikenId": 2,
		"parentNo": 1,
		"no": 3,
		"groupId": 1,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "最後に、Ｍさんは、小規模企業共済制度について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>「小規模企業共済制度は、個人事業主が廃業等した場合に必要となる資金を準備しておくための制度です。毎月の掛金は、1,000円から（ ① ）円までの範囲内（500円単位）で選択でき、支払った掛金は（ ② ）の対象となります。共済金（死亡事由以外）の受取方法には『一括受取り』 『分割受取り』 『一括受取りと分割受取りの併用』がありますが、このうち、『一括受取り』の共済金（死亡事由以外）は、（ ③ ）として所得税の課税対象となります」</div>",
		"choice1": "① 70,000 ② 税額控除 ③ 一時所得",
		"choice2": "① 68,000 ② 税額控除 ③ 退職所得",
		"choice3": "① 70,000 ② 所得控除 ③ 退職所得",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "6-1.png"
	},
	{
		"id": 279,
		"shikenId": 2,
		"parentNo": 1,
		"no": 4,
		"groupId": 2,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "はじめに、Ｍさんは、Ａさんが個人事業主となった場合の社会保険の取扱いについて説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
		"choice1": "「Ａさんは、退職日の翌日から60歳になるまでの間、健康保険に任意継続被保険者として加入することができます」",
		"choice2": "「Ａさんが国民健康保険に加入した場合、妻Ｂさんを国民健康保険の被扶養者とすることができます」",
		"choice3": "「ＡさんがＸ社を退職し、厚生年金保険の被保険者でなくなった場合、妻Ｂさんは、国民年金の第３号被保険者から第１号被保険者への種別変更の届出を行い、国民年金の保険料を納付することになります」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "6-2.png"
	},
	{
		"id": 280,
		"shikenId": 2,
		"parentNo": 1,
		"no": 5,
		"groupId": 2,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "次に、Ｍさんは、Ａさんに想定されるリスクについて説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
		"choice1": "「Ａさんのような公的介護保険の第２号被保険者は、要介護状態または要支援状態となった原因が特定疾病によって生じたものでなければ、公的介護保険からの保険給付は受けられません」",
		"choice2": "「個人事業主が加入する国民健康保険には、高額療養費制度が設けられていないため、会社員に比べて医療費の自己負担額が多くなる傾向があります。そのため、ケガや病気の場合の治療費の準備を充実させることをご検討ください」",
		"choice3": "「個人事業主となったＡさんが生活習慣病等で働けなくなった場合、会社員のときよりも収入が減少するリスクが高くなると思われます。そのため、治療費の準備に加えて、働けなくなった場合の所得補償の準備もご検討ください」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "6-2.png"
	},
	{
		"id": 281,
		"shikenId": 2,
		"parentNo": 1,
		"no": 6,
		"groupId": 2,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "最後に、Ｍさんは、生命保険の見直しについてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も不適切なものはどれか。",
		"choice1": "「会社員と個人事業主とでは、妻Ｂさんが受け取る公的年金等の総額や、死亡退職金の有無など、必要保障額を計算する際の条件が異なります。Ａさんが個人事業主となった場合の必要保障額を計算することをお勧めします」",
		"choice2": "「最近では、入院１日目から相応の一時金が支払われるタイプや、退院後の通院治療のために給付金が支払われるタイプの保険（特約）が販売されています。保険会社各社の保障内容をよく比較して、見直しを検討しましょう」",
		"choice3": "「現在加入している生命保険を払済終身保険に変更した場合、死亡保険金額は減少しますが、現在付加されている入院特約は残り、月々の保険料負担は軽減されます」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 4,
		"image": "6-2.png"
	},
	{
		"id": 282,
		"shikenId": 2,
		"parentNo": 1,
		"no": 7,
		"groupId": 3,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "仮に、将来Ｘ社がＡさんに役員退職金5,000万円を支給した場合、Ａさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ａさんの役員在任期間（勤続年数）を38年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
		"choice1": "1,170万円",
		"choice2": "1,470万円",
		"choice3": "2,940万円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 3,
		"image": "6-3.png"
	},
	{
		"id": 283,
		"shikenId": 2,
		"parentNo": 1,
		"no": 8,
		"groupId": 3,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｍさんは《設例》の終身保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
		"choice1": "「当該終身保険の支払保険料は、その全額を損金の額に算入することができます」",
		"choice2": "「Ｘ社が保険期間中に資金が必要となった場合に、契約者貸付制度を利用することにより、当該終身保険契約を解約することなく、資金を調達することができます」",
		"choice3": "「Ａさんの勇退時に、契約者をＡさん、死亡保険金受取人をＡさんの相続人に名義変更して、当該終身保険契約を役員退職金の一部として現物支給した場合、契約は継続しているため、Ｘ社での経理処理は必要ありません」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "6-3.png"
	},
	{
		"id": 284,
		"shikenId": 2,
		"parentNo": 1,
		"no": 9,
		"groupId": 3,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ｍさんは、中小企業退職金共済制度（以下、「中退共」という）の特徴について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>「中退共は、中小企業の事業主が退職金を社外に積み立てる退職金準備の共済制度です。毎月の掛金は、原則として、被共済者（従業員）１人につき月額5,000円から30,000円のなかから任意に選択することができ、その（ ① ）を損金の額に算入することができます。また、新しく中退共に加入する事業主に対して、原則として、掛金月額の（ ② ）（従業員ごと上限5,000円）を加入後４カ月目から１年間、国が助成する制度があります。被共済者（従業員）が中途（生存）退職したときは、退職金が（ ③ ）支給されます」</div>",
		"choice1": "① 全額 ② ３分の１ ③ 法人を経由して従業員に",
		"choice2": "① ２分の１ ② ３分の１ ③ 従業員本人に直接",
		"choice3": "① 全額 ② ２分の１ ③ 従業員本人に直接",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "6-3.png"
	},
	{
		"id": 285,
		"shikenId": 2,
		"parentNo": 1,
		"no": 10,
		"groupId": 4,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。",
		"choice1": "565万円",
		"choice2": "590万円",
		"choice3": "615万円<br><br>〈資料〉給与所得控除額<br><img src='/img/67_1.png' width='500'>",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "6-4.png"
	},
	{
		"id": 286,
		"shikenId": 2,
		"parentNo": 1,
		"no": 11,
		"groupId": 4,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における所得控除の控除額に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>ⅰ）「Ａさんが適用を受けることができる配偶者控除の控除額は、（ ① ）万円です」<br> ⅱ）「母Ｃさんは老人扶養親族の同居老親等に該当するため、Ａさんが適用を受けることができる扶養控除の控除額は、（ ② ）万円です」<br> ⅲ）「Ａさんが適用を受けることができる基礎控除の控除額は、（ ③ ）万円です」</div>",
		"choice1": "① 26 ② 38 ③ 48",
		"choice2": "① 26 ② 58 ③ 38",
		"choice3": "① 38 ② 58 ③ 48",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "6-4.png"
	},
	{
		"id": 287,
		"shikenId": 2,
		"parentNo": 1,
		"no": 12,
		"groupId": 4,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの2024年分の所得税における生命保険料控除に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "「Ａさんが加入した終身医療保険に係る保険料は、介護医療保険料控除の対象となります」",
		"choice2": "「Ａさんが生命保険料控除として総所得金額から控除することができる金額は、100,000円です」",
		"choice3": "「生命保険料控除は、生命保険に加入した年分については勤務先の年末調整で適用を受けることができず、所得税の確定申告が必要となります」",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 3,
		"image": "6-4.png"
	},
	{
		"id": 288,
		"shikenId": 2,
		"parentNo": 1,
		"no": 13,
		"groupId": 5,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "生前贈与に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが暦年課税を選択した場合、その年にＡさんから長女Ｃさんへ贈与した財産の価額が贈与税の基礎控除額を超えるときは、贈与したＡさんが贈与税の申告書を提出しなければなりません」",
		"choice2": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが相続時精算課税制度を選択した場合、累計で3,000万円までの贈与について贈与税は課されません」",
		"choice3": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが相続時精算課税制度を選択した場合、その選択をした年分以降にＡさんから長女Ｃさんへ贈与する財産について、暦年課税へ変更することはできません」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "6-5.png"
	},
	{
		"id": 289,
		"shikenId": 2,
		"parentNo": 1,
		"no": 14,
		"groupId": 5,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "Ａさんの相続等に関する次の記述のうち、最も適切なものはどれか。",
		"choice1": "「妻Ｂさんが自宅の敷地を相続により取得、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けた場合、自宅の敷地について相続税の課税価格に算入すべき価額は5,600万円となります」",
		"choice2": "「円滑な遺産分割のための手段として遺言書の作成をお勧めします。自筆証書遺言は、その遺言の全文および財産目録をパソコンで作成し、日付および氏名を自書して押印することで作成することができます」",
		"choice3": "「契約者（＝保険料負担者）および被保険者をＡさん、死亡保険金受取人を推定相続人とする終身保険に加入することをお勧めします。死亡保険金受取人が受け取る死亡保険金は、『500万円×法定相続人の数』を限度として、死亡保険金の非課税金額の規定の適用を受けることができます」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 3,
		"image": "6-5.png"
	},
	{
		"id": 290,
		"shikenId": 2,
		"parentNo": 1,
		"no": 15,
		"groupId": 5,
		"answerType": 3,
		"inputType": 3,
		"question1": "",
		"question2": "仮に、Ａさんの相続が現時点（2024年５月23日）で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が9,000万円であった場合の相続税の総額は、次のうちどれか。 ",
		"choice1": "1) 1,200万円",
		"choice2": "2) 1,275万円",
		"choice3": "3) 2,000万円<div class='pt-3'>        <img src='/img/88_2.png' width='420'/>      </div>",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 4,
		"image": "6-5.png"
	},
	{
		"id": 291,
		"shikenId": 2,
		"parentNo": 1,
		"no": 1,
		"groupId": 1,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を順守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "生命保険募集人、保険仲立人の登録を受けていないＦＰが、生命保険契約を検討している顧客のライフプランに基づき、必要保障額を具体的に試算し、相談料金を受け取った。",
		"choice2": "投資助言・代理業の登録を受けていないＦＰが、顧客と投資顧問契約を締結し、当該契約に基づいて具体的な投資銘柄と投資タイミングについて有償で助言をした。",
		"choice3": "税理士資格を有していないＦＰが、相続対策を検討している顧客に対し、一般的な相続税制度の仕組みと手順を解説し、相談料金を受け取った。",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 292,
		"shikenId": 2,
		"parentNo": 1,
		"no": 2,
		"groupId": 1,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "下記は、服部家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）、（イ）にあてはまる数値の組み合わせとして、正しいものはどれか。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src=\"/img/question/201_1.png\" class=\"question-img\" /><br> <br> ※年齢および金融資産残高は各年１２月３１日現在のものとし、２０２４年を基準年とする。<br> ※給与収入は可処分所得で記載している。<br> ※記載されている数値は正しいものとする。<br> ※問題作成の都合上、一部を空欄にしてある。",
		"choice1": "（ア）４１０ （イ）８７４",
		"choice2": "（ア）４１１ （イ）１,０５１",
		"choice3": "（ア）４２０ （イ）１,０５２",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 293,
		"shikenId": 2,
		"parentNo": 1,
		"no": 3,
		"groupId": 2,
		"answerType": 4,
		"inputType": 4,
		"question1": "長谷川大輔さんは株式会社ＰＥに勤務する会社員である。大輔さんは今後の生活設計について、ＦＰで税理士でもある上埜さんに相談をした。なお、下記のデータはいずれも２０２４年９月１日現在のものである。<br><img src=\"/img/question/202_1.png\" class=\"question-img\" style=\"width:340px\" /><br> <br> ［負債残高］<br> 住宅ローン（自宅マンション）：２,１００万円（債務者は大輔さん、団体信用生命保険付き）<br> <br> ［その他］<br> 上記以外については、特に指定のない限り一切考慮しないものとする。",
		"question2": "ＦＰの上埜さんは、長谷川家の２０２４年９月１日現在のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、上記のデータに基づいて解答することとする。<br><img src=\"/img/question/202_2.png\" class=\"question-img\" />",
		"choice1": "１,２２０（万円）",
		"choice2": "１,９２０（万円）",
		"choice3": "１,９６０（万円）",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 294,
		"shikenId": 2,
		"parentNo": 1,
		"no": 4,
		"groupId": 2,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "会社員の山田遼さんは、６０歳で定年を迎えた後、公的年金の支給が始まる６５歳までの５年間の生活資金に退職一時金の一部を充てようと考えている。仮に、退職一時金のうち６００万円を年利１.０％で複利運用しながら５年間で均等に取り崩すこととした場合、毎年の生活資金に充てることができる最大金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては万円未満を切り捨てること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src=\"/img/question/203_1.png\" class=\"question-img\" />",
		"choice1": "１１４万円",
		"choice2": "１１７万円",
		"choice3": "１２３万円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 295,
		"shikenId": 2,
		"parentNo": 1,
		"no": 5,
		"groupId": 2,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "会社員の石戸修二さんは、通常６５歳から支給される老齢基礎年金および老齢厚生年金を繰り下げて受給できることを知り、ＦＰの矢尾さんに質問をした。老齢基礎年金および老齢厚生年金の繰下げ受給に関する次の記述のうち、最も不適切なものはどれか。なお、老齢基礎年金および老齢厚生年金の受給要件は満たしているものとする。",
		"choice1": "老齢基礎年金および老齢厚生年金を繰り下げて受給した場合の年金額は、繰下げ年数１年当たり７％の割合で増額された額となる。",
		"choice2": "老齢基礎年金と老齢厚生年金は、どちらか一方のみを繰り下げて受給することができる。",
		"choice3": "老齢基礎年金および老齢厚生年金を繰り下げて受給した場合には、一生涯増額された年金を受給することになる。",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 296,
		"shikenId": 2,
		"parentNo": 1,
		"no": 6,
		"groupId": 3,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "下記＜資料＞は、ＭＸファンドの販売用資料（一部抜粋）である。この投資信託に関する次の記述のうち、最も不適切なものはどれか。<br><img src=\"/img/question/205_1.png\" class=\"question-img\" />",
		"choice1": "ＭＸファンドは、ＮＩＳＡ（少額投資非課税制度）口座で購入することができる",
		"choice2": "ＭＸファンドは、運用状況によっては収益分配金が支払われないこともある。",
		"choice3": "ＭＸファンドを購入する際、投資家が支払う購入代金は<br>「<img src=\"/img/question/205_2.png\" style=\"width:138px\" /> ×購入口数＋購入時手数料（税込）＋運用管理費用（信託報酬）（税込）」である。",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 297,
		"shikenId": 2,
		"parentNo": 1,
		"no": 7,
		"groupId": 3,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "下記＜資料＞の外貨定期預金について、満期時の外貨ベースの元利合計額を円転した金額として、正しいものはどれか。なお、計算結果（円転した金額）について円未満の端数が生じる場合は切り捨てること。また、税金については考慮しないこととする。<br><img src=\"/img/question/206_1.png\" class=\"question-img\" />",
		"choice1": "７８２,５０５円",
		"choice2": "７７７,４８３円",
		"choice3": "７７２,４６０円",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 298,
		"shikenId": 2,
		"parentNo": 1,
		"no": 8,
		"groupId": 4,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "小堀さんは、預金保険制度の対象となるＨＡ銀行の国内支店に下記＜資料＞の預金を預け入れている。<br> 仮に、ＨＡ銀行が破たんした場合、預金保険制度によって保護される金額に関する次の記述のうち、最も不適切なものはどれか。<br><img src=\"/img/question/207_1.png\" class=\"question-img\" />",
		"choice1": "決済用預金１,５００万円は全額保護される。",
		"choice2": "円定期預金および円普通預金は、合算し１,０００万円が保護される。",
		"choice3": "外貨預金２００万円は全額保護される。",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 299,
		"shikenId": 2,
		"parentNo": 1,
		"no": 9,
		"groupId": 4,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "公的な土地評価に関する下表の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><img src=\"/img/question/208_1.png\" class=\"question-img\" />",
		"choice1": "（ア）総務省 （イ）市町村（東京２３区は東京都） （ウ）７０％",
		"choice2": "（ア）国土交通省 （イ）市町村（東京２３区は東京都） （ウ）８０％",
		"choice3": "（ア）国土交通省 （イ）国税庁 （ウ）９０％",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 300,
		"shikenId": 2,
		"parentNo": 1,
		"no": 10,
		"groupId": 4,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "下記＜資料＞の甲土地の建築面積の最高限度を算出する基礎となる敷地面積として、正しいものはどれか。なお、この土地の存する区域は特定行政庁が指定する区域に該当しないものとし、その他記載のない条件については一切考慮しないこととする。<br><img src=\"/img/question/209_1.png\" class=\"question-img\" />",
		"choice1": "１２０ｍ<sup>2</sup>",
		"choice2": "１８０ｍ<sup>2</sup>",
		"choice3": "１９０ｍ<sup>2</sup>",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 301,
		"shikenId": 2,
		"parentNo": 1,
		"no": 11,
		"groupId": 5,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "下表は、定期借地権についてまとめた表である。下表の空欄（ア）～（ウ）にあてはまる数値または語句の組み合わせとして、最も適切なものはどれか。<br><img src=\"/img/question/210_1.png\" class=\"question-img\" />",
		"choice1": "（ア）３０ （イ）居住用 （ウ）借地人",
		"choice2": "（ア）５０ （イ）事業用 （ウ）土地所有者",
		"choice3": "（ア）５０ （イ）居住用 （ウ）土地所有者",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 302,
		"shikenId": 2,
		"parentNo": 1,
		"no": 12,
		"groupId": 5,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "皆木慎太朗さんが加入している医療保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとする。また、慎太朗さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src=\"/img/question/211_1.png\" class=\"question-img\" />",
		"choice1": "３００,０００円",
		"choice2": "４００,０００円",
		"choice3": "４１５,０００円",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 303,
		"shikenId": 2,
		"parentNo": 1,
		"no": 13,
		"groupId": 6,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "ＦＰの清野さんは相談者の角田さんから地震保険に関する質問を受けた。地震保険に関する清野さんの次の説明のうち、最も不適切なものはどれか。",
		"choice1": "「地震保険は、住宅総合保険や火災保険などとセットで契約するため、単独での契約はできません。」",
		"choice2": "「地震保険の保険料は保険会社ごとに異なるので、数社から見積りを取った方が良いでしょう。」",
		"choice3": "「噴火により、居住用の建物が全損となった場合、地震保険の補償の対象となります。」",
		"choice4": "",
		"choice5": "",
		"answer": 2,
		"score": 5,
		"image": ""
	},
	{
		"id": 304,
		"shikenId": 2,
		"parentNo": 1,
		"no": 14,
		"groupId": 6,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "損害保険の用語に関する次の記述のうち、最も不適切なものはどれか。",
		"choice1": "保険金とは、保険事故により損害が生じたとき、保険会社が被保険者または保険金受取人に支払う金銭のことである。",
		"choice2": "超過保険とは、保険金額が保険の対象の価額（保険価額）を超えている保険のことである。",
		"choice3": "再調達価額とは、保険の対象と同等のものを新たに建築または購入するのに必要な金額から、使用による消耗分を差し引いた金額のことである。",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 305,
		"shikenId": 2,
		"parentNo": 1,
		"no": 15,
		"groupId": 6,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "西住さんは、２０２４年７月に新築のアパートを購入し、新たに不動産賃貸業を開始した。購入したアパートの建物部分の情報は下記＜資料＞のとおりである。西住さんの２０２４年分の所得税における不動産所得の金額の計算上、必要経費に算入する減価償却費の金額として、正しいものはどれか。<br><img src=\"/img/question/215_1.png\" class=\"question-img\" />",
		"choice1": "８２５,０００円",
		"choice2": "１,６１２,５００円",
		"choice3": "１,６５０,０００円",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 306,
		"shikenId": 2,
		"parentNo": 1,
		"no": 16,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "赤木さんは、自宅として所有している土地と建物を２０２４年中に譲渡する予定である。赤木さんの土地と建物の譲渡に係る所得税の計算に関する次の記述の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br> <br> <div class='b-box'>・ 土地と建物などの譲渡による所得は（ ア ）所得として（ イ ）課税の対象となる。<br> ・ 土地と建物などの（ ア ）所得の金額は原則として、「譲渡価額－取得費－（ ウ ）」として計算する。</div>",
		"choice1": "（ア）譲渡　　（イ）総合　（ウ）必要経費",
		"choice2": "（ア）不動産　（イ）総合　（ウ）必要経費",
		"choice3": "（ア）譲渡　　（イ）分離　（ウ）譲渡費用",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 307,
		"shikenId": 2,
		"parentNo": 1,
		"no": 17,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "会社員の中野望さんが２０２４年中に支払った医療費等が下記＜資料＞のとおりである場合、望さんの２０２４年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、望さんの２０２４年中の所得は、給与所得８００万円のみであり、支払った医療費等はすべて望さんおよび生計を一にする妻のために支払ったものである。また、医療費控除の金額が最も大きくなるよう計算することとし、セルフメディケーション税制（特定一般用医薬品等購入費を支払った場合の医療費控除の特例）については、考慮しないものとする。<br><img src=\"/img/question/217_1.png\" class=\"question-img\" /><br> <br> （※１）人間ドックの結果、重大な疾病は発見されていない。<br> （※２）この入院について、加入中の生命保険から入院給付金が６万円支給された。",
		"choice1": "１９万円",
		"choice2": "２５万円",
		"choice3": "２７万円",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 308,
		"shikenId": 2,
		"parentNo": 1,
		"no": 18,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "２０２４年１月５日に相続が開始された三河太一朗さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src=\"/img/question/218_1.png\" class=\"question-img\" />",
		"choice1": "美佐子 ２／３　みき １／３",
		"choice2": "美佐子 １／２　みき １／２",
		"choice3": "美佐子 １／２　優佳 １／２",
		"choice4": "",
		"choice5": "",
		"answer": 1,
		"score": 5,
		"image": ""
	},
	{
		"id": 309,
		"shikenId": 2,
		"parentNo": 1,
		"no": 19,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "相続開始後の各種手続きにおける下記＜資料＞の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、記載のない事項については一切考慮しないこととする。<br><img src=\"/img/question/219_1.png\" class=\"question-img\" />",
		"choice1": "（ア）地方裁判所 （イ） ６ヵ月",
		"choice2": "（ア）地方裁判所 （イ）１０ヵ月",
		"choice3": "（ア）家庭裁判所 （イ）１０ヵ月",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},
	{
		"id": 310,
		"shikenId": 2,
		"parentNo": 1,
		"no": 20,
		"groupId": 7,
		"answerType": 4,
		"inputType": 4,
		"question1": "",
		"question2": "姉歯稔さん（７８歳）は、将来発生するであろう自身の相続について、遺産分割等でのトラブルを防ぐために公正証書遺言の作成を検討しており、ＦＰの桐谷さんに相談をした。公正証書遺言に関する桐谷さんの次の説明のうち、最も適切なものはどれか。",
		"choice1": "「すでに作成した公正証書遺言を撤回したい場合、自筆証書遺言では撤回することはできません。」",
		"choice2": "「公正証書遺言を作成する場合、証人の立会いは必要ありません。」",
		"choice3": "「公正証書遺言を作成した場合、相続発生後、家庭裁判所に対してその検認を請求する必要はありません。」",
		"choice4": "",
		"choice5": "",
		"answer": 3,
		"score": 5,
		"image": ""
	},



    {
        "id": 1001,
        "shikenId": 20,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "ファイナンシャル・プランナー（以下「ＦＰ」という）の顧客に対する行為に関する次の記述のうち、関連法規に照らし、最も不適切なものはどれか。",
        "choice1": "金融商品取引業の登録を受けていないＦＰのAさんは、顧客から確定拠出年金の個人型年金（ｉＤｅＣｏ）について相談を受け、ｉＤｅＣｏの運用商品の特徴について説明した 。",
        "choice2": "社会保険労務士の資格を有しないＦＰのBさんは、顧客から公的年金の老齢給付の繰上げ受給について相談を受け、顧客の「ねんきん定期便」の年金受取見込額を基に、繰り上げた場合の年金額を試算した。",
        "choice3": "司法書士の資格を有しないＦＰのCさんは、住宅ローンを完済した顧客から、抵当権の抹消登記について相談を受け、申請書を作成して登記手続を代行した。",
        "choice4": "税理士の資格を有しないＦＰのDさんは、顧客からふるさと納税について相談を受け、寄附金控除の仕組みについて説明した。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1002,
        "shikenId": 20,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "ライフプランニングにおけるライフステージ別の一般的な資金の活用等に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "Aさん（６０歳）は、退職金と預貯金のうち、今後の生活資金を確保した残りの余裕資金から、子が住宅を取得するための頭金として、税務上非課税となる範囲で現金を贈与することとした。",
        "choice2": "Bさん（２５歳）は、子の教育資金を準備するため、金融機関のカードローンで資金を借り入れ、高リスクだが、高い収益が見込める金融商品を購入して積極的な運用を図ることとした。",
        "choice3": "Cさん（７０歳）は、相続対策として、相続人がもめないように、遺言執行者として弁護士を指定した自筆証書遺言を作成し、法務局（遺言書保管所）に保管の申請をすることとした。",
        "choice4": "Dさん（４０歳）は、老後の生活資金を充実させるために、確定拠出年金の個人型年金（ｉＤｅＣｏ）を利用して余裕資金を運用することとした。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1003,
        "shikenId": 20,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "労働者災害補償保険（以下「労災保険」という）に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "業務上の負傷または疾病が治癒したときに身体に一定の障害が残り、その障害の程度が労働者災害補償保険法に規定する障害等級に該当する場合、障害補償給付が受けられる。",
        "choice2": "労災保険の適用を受ける労働者には、雇用形態がアルバイトやパートタイマーである者も含まれる。",
        "choice3": "労災保険の保険料を計算する際に用いる保険料率は、適用事業所の事業の種類により異なる。",
        "choice4": "労災保険の適用事業所の事業主は、その営む事業において使用する労働者数の多寡にかかわらず、労災保険の特別加入の対象となる。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1004,
        "shikenId": 20,
        "parentNo": 1,
        "no": 4,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "雇用保険法に基づく育児休業給付および介護休業給付に関する次の記述のうち、最も適切なものはどれか。なお、記載されたもの以外の要件はすべて満たしているものとする。",
        "choice1": "育児休業給付金に係る支給単位期間において、一般被保険者や高年齢被保険者に対して支払われた賃金額が、休業開始時賃金日額に支給日数を乗じて得た額の６０％相当額以上である場合、当該支給単位期間について育児休業給付金は支給されない。",
        "choice2": "一般被保険者や高年齢被保険者の父母および配偶者の父母は、介護休業給付金の支給対象となる家族に該当しない。",
        "choice3": "一般被保険者や高年齢被保険者が、一定の状態にある家族を介護するために休業する場合、同一の対象家族について、通算３回かつ１８０日の介護休業を限度とし、介護休業給付金が支給される。",
        "choice4": "一般被保険者や高年齢被保険者が、１歳に満たない子を養育するために休業する場合、育児休業給付金が支給される。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1005,
        "shikenId": 20,
        "parentNo": 1,
        "no": 5,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "公的年金に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "産前産後休業を取得している厚生年金保険の被保険者の厚生年金保険料は、所定の手続きにより、被保険者負担分と事業主負担分がいずれも免除される。",
        "choice2": "老齢厚生年金の受給権者が老齢厚生年金の繰下げ支給の申出をする場合、老齢基礎年金の繰下げ支給の申出を同時に行わなければならない。",
        "choice3": "老齢厚生年金の額に加給年金額が加算されるためには、原則として、厚生年金保険の被保険者期間が１０年以上であり、かつ、その受給権者によって生計を維持されている一定の要件を満たす配偶者または子がいる必要がある。",
        "choice4": "国民年金の第１号被保険者は、日本国内に住所を有する２０歳以上６０歳未満の自営業者や学生などのうち、日本国籍を有する者のみが該当する。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1006,
        "shikenId": 20,
        "parentNo": 1,
        "no": 6,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "国民年金基金、小規模企業共済および中小企業退職金共済に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "国民年金基金には、国民年金の第３号被保険者が加入することはできない。",
        "choice2": "国民年金基金には、国内に住所を有する６０歳以上６５歳未満の国民年金の任意加入被保険者も加入することができる。",
        "choice3": "小規模企業共済に加入した場合、支払った掛金額の全額が小規模企業共済等掛金控除として所得税の所得控除の対象となる。",
        "choice4": "中小企業退職金共済に新規で加入する事業主は、加入月から１年間、掛金月額の２分の１相当額（従業員ごとに５,０００円が上限）について国の助成を受けることができる。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1007,
        "shikenId": 20,
        "parentNo": 1,
        "no": 7,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "公的年金に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "障害基礎年金と遺族厚生年金の受給権を有している者は、６５歳以降、障害基礎年金と遺族厚生年金を同時に受給することができない。",
        "choice2": "障害基礎年金と老齢厚生年金の受給権を有している者は、６５歳以降、障害基礎年金と老齢厚生年金を同時に受給することができない。",
        "choice3": "同一の事由により、障害厚生年金と労働者災害補償保険法に基づく障害補償年金が支給される場合、障害補償年金は全額支給され、障害厚生年金は所定の調整率により減額される。",
        "choice4": "健康保険の傷病手当金の支給を受けるべき者が、同一の疾病または負傷およびこれにより発した疾病について障害厚生年金の支給を受けることができる場合、原則として傷病手当金は支給されない。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1008,
        "shikenId": 20,
        "parentNo": 1,
        "no": 8,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "住宅金融支援機構と金融機関が提携した住宅ローンであるフラット３５（買取型）に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "フラット３５Ｓは、省エネルギー性、耐震性など一定の技術基準を満たした住宅を取得する場合に、借入金利を一定期間引き下げる制度である。",
        "choice2": "フラット３５の利用者向けインターネットサービスである「住・ＭｙＮｏｔｅ」を利用して繰上げ返済する場合、一部繰上げ返済の最低返済額は１０万円である。",
        "choice3": "店舗付き住宅などの併用住宅を建築する際に、フラット３５を利用することはできない。",
        "choice4": "住宅金融支援機構は、融資を実行する金融機関から住宅ローン債権を買い取り、対象となる住宅の第１順位の抵当権者となる。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1009,
        "shikenId": 20,
        "parentNo": 1,
        "no": 9,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "リタイアメントプランニング等に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "金融機関のリバースモーゲージには、一般に、利用者が死亡し、担保物件の売却代金により借入金を返済した後も債務が残った場合に、利用者の相続人がその返済義務を負う「ノンリコース型」と、返済義務を負わない「リコース型」がある。",
        "choice2": "高齢者の居住の安定確保に関する法律に定める「サービス付き高齢者向け住宅」に入居した者は、「状況把握サービス」や「生活相談サービス」を受けることができる。",
        "choice3": "将来、本人の判断能力が不十分になった場合に備えて、あらかじめ本人が選任した者と締結する任意後見契約は、公正証書によらない場合であっても有効である。",
        "choice4": "確定拠出年金の加入者が、老齢給付金を６０歳から受給するためには、通算加入者等期間が５年以上なければならない。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1010,
        "shikenId": 20,
        "parentNo": 1,
        "no": 10,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "クレジットカード会社（貸金業者）が発行するクレジットカードの一般的な利用に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "クレジットカードで商品を購入（ショッピング）した場合の返済方法の一つである定額リボルビング払い方式は、クレジットの利用金額・件数に関わらず、あらかじめ設定した一定の金額を支払う方式である。",
        "choice2": "クレジットカードで無担保借入（キャッシング）をする行為は、貸金業法上、総量規制の対象となる。",
        "choice3": "クレジットカード会員規約では、原則としてクレジットカードは他人へ貸与することが禁止されているが、クレジットカード会員が生計を維持している親族に対しては貸与することができる。",
        "choice4": "クレジットカード会員の信用情報は、クレジットカード会社が加盟する指定信用情報機関により管理されており、会員は自己の信用情報について所定の手続きにより開示請求をすることができる。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1011,
        "shikenId": 20,
        "parentNo": 1,
        "no": 11,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "個人年金保険の一般的な商品性に関する次の記述のうち、最も適切なものはどれか。なお、契約者（＝保険料負担者）、被保険者および年金受取人は同一人とする。",
        "choice1": "外貨建て個人年金保険では、円換算支払特約を付加することで、契約時点の為替レートで円換算した年金を受け取ることができる。",
        "choice2": "個人年金保険では、被保険者が保険料払込期間中に所定の高度障害状態に該当すると、以後の保険料の払込みが免除され、直ちに年金を受け取ることができる。",
        "choice3": "個人年金保険（保証期間付終身年金）では、保証期間中に被保険者が死亡した場合、死亡給付金受取人が、既払込保険料相当額からすでに支払われた年金額を差し引いた金額を死亡給付金として受け取ることができる。",
        "choice4": "生存保障重視型の個人年金保険では、他の契約条件が同一で生存保障重視型ではない個人年金保険と比較して、保険料払込期間中の死亡給付金や解約返戻金の額が低く設定され、将来受け取る年金額が高く設定されている。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1012,
        "shikenId": 20,
        "parentNo": 1,
        "no": 12,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "生命保険の一般的な商品性に関する次の記述のうち、最も適切なものはどれか。なお、特約については考慮しないものとする。",
        "choice1": "養老保険では、被保険者に高度障害保険金が支払われた場合であっても、その被保険者が保険期間満了まで生存したときには満期保険金が支払われる。",
        "choice2": "収入保障保険の死亡保険金を年金形式で受け取る場合の受取総額は、一時金で受け取る場合の受取額と同額である。",
        "choice3": "変額保険（終身型）の死亡保険金については、運用実績に応じて保険金額が変動するが、契約時に定めた保険金額（基本保険金額）は保証される。",
        "choice4": "こども保険（学資保険）では、契約者が死亡した場合、あらかじめ指定された受取人に死亡給付金が支払われる。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1013,
        "shikenId": 20,
        "parentNo": 1,
        "no": 13,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "生命保険料控除に関する次の記述のうち、最も適切なものはどれか。なお、各選択肢において、ほかに必要とされる要件等はすべて満たしているものとする。",
        "choice1": "養老保険の保険料は、介護医療保険料控除の対象となる。",
        "choice2": "少額短期保険の保険料は、その保険契約が被保険者の死亡時に死亡保険金が支払われるものである場合、一般の生命保険料控除の対象となる。",
        "choice3": "終身保険の月払保険料のうち、２０２５年１月に払い込まれた２０２４年１２月分の保険料は、２０２４年分の一般の生命保険料控除の対象となる。",
        "choice4": "変額個人年金保険の保険料は、個人年金保険料控除の対象とはならず、一般の生命保険料控除の対象となる。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1014,
        "shikenId": 20,
        "parentNo": 1,
        "no": 14,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "個人年金保険の税金に関する次の記述のうち、最も不適切なものはどれか。なお、いずれも契約者（＝保険料負担者）は個人であるものとする。",
        "choice1": "契約者と被保険者が異なる個人年金保険において、年金支払開始前に被保険者が死亡して契約者が受け取った死亡給付金は、所得税の課税対象となる。",
        "choice2": "契約者と年金受取人が異なる個人年金保険において、年金支払開始時に年金受取人が取得した年金受給権は、贈与税の課税対象となる。",
        "choice3": "契約者と年金受取人が同一人である個人年金保険（保証期間付終身年金）において、保証期間中に年金受取人が死亡して遺族が取得した残りの保証期間の年金受給権は、相続税の課税対象となる。",
        "choice4": "契約者と年金受取人が同一人である個人年金保険において、年金受取人が毎年受け取る年金は、雑所得として公的年金等控除の対象となる。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1015,
        "shikenId": 20,
        "parentNo": 1,
        "no": 15,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "法人を契約者（＝保険料負担者）とする生命保険に係る保険料の経理処理に関する次の記述のうち、最も適切なものはどれか。なお、いずれの保険契約も保険料は年払いかつ全期払いで、２０２４年１０月に締結したものとする。",
        "choice1": "被保険者が役員および従業員全員、死亡保険金受取人が被保険者の遺族、満期保険金受取人が法人である養老保険の支払保険料は、その全額を損金の額に算入することができる。",
        "choice2": "被保険者が役員、死亡保険金受取人が法人である終身保険の支払保険料は、その全額を損金に計上する。",
        "choice3": "被保険者が役員、死亡保険金受取人が法人で、最高解約返戻率が６０％である定期保険（保険期間２０年、年払保険料１００万円）の支払保険料は、保険期間の前半１００分の４０相当期間においては、その６０％相当額を限度に損金の額に算入することができる。",
        "choice4": "被保険者が役員、給付金受取人が法人である解約返戻金のない医療保険の支払保険料は、資産の額に算入する。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1016,
        "shikenId": 20,
        "parentNo": 1,
        "no": 16,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "任意加入の自動車保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、記載のない事項については考慮しないものとする。",
        "choice1": "対物賠償保険では、被保険自動車を運転中に、父の自宅の車庫に誤って衝突して車庫を損壊させた場合、補償の対象となる。",
        "choice2": "対人賠償保険では、被保険自動車を運転中に、対人事故を起こした被保険者が法律上の損害賠償責任を負った場合、自動車損害賠償責任保険等によって補償される部分を除いた額が補償の対象となる。",
        "choice3": "人身傷害保険では、被保険者が被保険自動車を運転中に起こした交通事故で死傷した場合、被保険者の過失割合にかかわらず、当該損害額が保険金額を限度として補償の対象となる。",
        "choice4": "一般条件の車両保険では、被保険自動車が洪水で水没した場合、補償の対象となる。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1017,
        "shikenId": 20,
        "parentNo": 1,
        "no": 17,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "住宅用建物および家財を保険の対象とする火災保険の一般的な商品性に関する次の記述のうち、最も適切なものはどれか。なお、特約については考慮しないものとする。",
        "choice1": "火災保険の保険料は、対象となる住宅用建物の構造により、Ｍ構造、Ｔ構造、Ｈ構造の３つに区分されて算定される。",
        "choice2": "保険金額が２,０００万円（保険価額と同額）の火災保険に加入した後、火災により住宅用建物が損害を被り、損害保険金１,０００万円が支払われた場合、保険契約は継続するが、保険期間満了日までの保険金額が１,０００万円に減額される。",
        "choice3": "火災保険では、隣家の火災の消火活動により住宅用建物に収容されている家財が損壊した場合、補償の対象とならない。",
        "choice4": "火災保険では、雪災により住宅用建物の屋根が損壊して１００万円の損害が発生した場合、補償の対象とならない。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1018,
        "shikenId": 20,
        "parentNo": 1,
        "no": 18,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "医療保険等の一般的な商品性に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "がん保険の入院給付金は、１回の入院における支払日数および通算の支払日数に制限はない。",
        "choice2": "先進医療特約で先進医療給付金の支払対象とされている先進医療は、契約時点において厚生労働大臣によって定められているものである。",
        "choice3": "１泊２日の入院検査（人間ドック検診）で異常が認められ、治療を目的とした入院を医師から指示された場合、その追加の入院については医療保険の入院給付金の支払対象とならない。",
        "choice4": "特定（三大）疾病保障定期保険では、被保険者が特定疾病に罹患し、特定疾病保険金を受け取った場合でも、その後被保険者が死亡した際には死亡保険金が支払われる。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1019,
        "shikenId": 20,
        "parentNo": 1,
        "no": 19,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "第三分野の保険の一般的な商品性に関する次の記述のうち、最も適切なものはどれか。なお、記載のない特約については考慮しないものとする。",
        "choice1": "所得補償保険では、ケガや病気によって就業不能となった場合であっても、所定の医療機関に入院しなければ、補償の対象とならない。",
        "choice2": "生命保険会社が取り扱う介護保険は、公的介護保険の加入年齢である40歳から加入可能となり、保険期間は65歳までとされる。",
        "choice3": "限定告知型の医療保険では、他の契約条件が同一で限定告知型ではない医療保険と比較して、割高な保険料が設定されている。",
        "choice4": "がん保険では、１８０日間または６ヵ月間の免責期間が設けられており、その期間中にがんと診断されても、がん診断給付金は支払われない。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1020,
        "shikenId": 20,
        "parentNo": 1,
        "no": 20,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "損害保険を利用した事業活動のリスク管理に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "家庭用品を製造する事業者が、製造した製品が原因で、当該製品を使用した顧客がケガをして法律上の損害賠償責任を負うリスクに備えて、生産物賠償責任保険（ＰＬ保険）を契約した。 ",
        "choice2": "ボウリング場を経営する事業者が、施設の管理不備により、来場者がケガをして法律上の損害賠償責任を負うリスクに備えて、受託者賠償責任保険を契約した。",
        "choice3": "建設業を営む事業者が、従業員が業務中の事故によりケガをする場合に備えて、労働者災害補償保険（政府労災保険）の上乗せとして企業費用・利益総合保険を契約した。",
        "choice4": "事業用ビルの賃貸業を営む事業者が、賃貸ビルに設置した機械設備が火災により損害を被る場合に備えて、機械保険を契約した。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1021,
        "shikenId": 20,
        "parentNo": 1,
        "no": 21,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "銀行等の金融機関で取り扱う預貯金の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "決済用預金は、「無利息」「要求払い」「決済サービスを提供できること」という３つの条件を満たした預金である。",
        "choice2": "当座預金は、株式の配当金の自動受取口座として利用することができる。",
        "choice3": "スーパー定期預金は、預入期間が３年以上の場合、単利型と半年複利型があるが、半年複利型を利用することができるのは法人に限られる。",
        "choice4": "大口定期預金は、最低預入金額が１,０００万円に設定された固定金利型の定期預金である。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1022,
        "shikenId": 20,
        "parentNo": 1,
        "no": 22,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "銀行等の金融機関で取り扱う預金の一般的な商品性等に関する次の記述のうち、最も不適切なものはどれか。なお、本問においては、「民間公益活動を促進するための休眠預金等に係る資金の活用に関する法律」を休眠預金等活用法という。",
        "choice1": "オプション取引などのデリバティブを組み込んだ仕組預金には、金融機関の判断によって満期日が繰り上がるものがある。",
        "choice2": "ゆうちょ銀行の預入限度額は、通常貯金と定期性貯金（財形貯金各種を除く）のそれぞれについて１,３００万円となっている。",
        "choice3": "期日指定定期預金は、据置期間経過後から最長預入期日までの間で、金融機関が指定した日が満期日となる。",
        "choice4": "２００９年１月１日以降、取引がないまま１０年が経過した預金等は、休眠預金等活用法に基づく「休眠預金等」に該当する。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1023,
        "shikenId": 20,
        "parentNo": 1,
        "no": 23,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "株式投資信託の運用手法および運用スタイルに関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "株価が現在の資産価値や利益水準などから割安と評価される銘柄に投資する手法は、グロース投資と呼ばれる。",
        "choice2": "ベンチマークを上回る運用成果を目指す株式投資信託の運用手法は、パッシブ運用と呼ばれる。",
        "choice3": "「ブル型」「ベア型」と呼ばれる特殊型の投資信託のうち、「ベア型」は、ベンチマークが上昇すると基準価額が上昇するように設計されている。",
        "choice4": "マクロ的な環境要因等を基に国別組入比率や業種別組入比率などを決定し、その比率に応じて、個別銘柄を組み入れてポートフォリオを構築する手法は、トップダウン・アプローチと呼ばれる。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1024,
        "shikenId": 20,
        "parentNo": 1,
        "no": 24,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "固定利付国債の利回り（単利・年率）と市場金利の変動との関係を説明した次の記述の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、手数料、経過利子、税金等については考慮しないものとし、国債のイールドカーブは順イールド（期間が長いものほど利回りが高い）の状況にあるものとする。<br><br><div class='b-box'>表面利率が１.０％、償還期限が５年（満期一括償還）の固定利付国債を発行時に額面１００円当たり１００円で購入し、１年後に売却した。売却時の市場金利が購入時よりも上昇していた場合、通常、当該国債の額面１００円当たりの売却価格は１００円を（ ア ）、当該国債の購入時から売却時までの所有期間利回りは１.０％（ イ ）。<br></div>",
        "choice1": "（ア）上回り （イ）よりも高くなる",
        "choice2": "（ア）下回り （イ）で変わらない",
        "choice3": "（ア）上回り （イ）で変わらない",
        "choice4": "（ア）下回り （イ）よりも低くなる",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1025,
        "shikenId": 20,
        "parentNo": 1,
        "no": 25,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "下記＜Ｘ社のデータ＞に基づき算出される投資指標等に関する次の記述のうち、最も適切なものはどれか。<br><br>＜X社のデータ＞<br><img src='/img/question/1025_1.png'  width='330px'/>",
        "choice1": "１株当たり当期純利益は、５０円である。",
        "choice2": "ＲＯＥ（自己資本当期純利益率）は、４０.０％である。",
        "choice3": "ＰＢＲ（株価純資産倍率）は、４.０倍である。",
        "choice4": "配当利回りは、７.５％である。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1026,
        "shikenId": 20,
        "parentNo": 1,
        "no": 26,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "株式の信用取引の一般的な仕組みに関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "信用取引では、現物株式を所有していない場合、その株式の「売り」から取引を開始することはできない。",
        "choice2": "制度信用取引の建株を一般信用取引の建株に変更することはできるが、一般信用取引の建株を制度信用取引の建株に変更することはできない。",
        "choice3": "信用取引では、売買が成立した後に相場が変動して証券会社が定める最低委託保証金維持率を下回った場合、追加保証金を差し入れるなどの方法により、委託保証金の不足を解消しなくてはならない。",
        "choice4": "金融商品取引法では、株式の信用取引を行う際の委託保証金の額は２０万円以上であり、かつ、当該取引に係る株式の時価に１００分の２０を乗じた金額以上でなければならないとされている。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1027,
        "shikenId": 20,
        "parentNo": 1,
        "no": 27,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "金融派生商品に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "金融派生商品を利用する場合、現物取引を行った場合と同等の投資効果を得るには、現物取引よりも多額の資金を投入する必要がある。",
        "choice2": "現物価格の変動による利益と同額の利益が発生するように、現物と同じポジションの先物を保有することなどにより、価格変動リスク等を回避または軽減することを狙う取引を、ヘッジ取引という。",
        "choice3": "現物価格と当該現物を原資産とする先物の理論価格との間で価格差が生じた場合、割安な方を売り、割高な方を買うポジションを組み、その価格差を利益として得ることを狙う取引を、裁定取引という。",
        "choice4": "先物の将来の価格を予想してポジションを取り、予想どおりの方向に変動したときに反対売買を行って利益を確定することを狙う取引を、スペキュレーション取引という。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1028,
        "shikenId": 20,
        "parentNo": 1,
        "no": 28,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "下記＜資料＞に基づくファンドＡとファンドＢの過去３年間の運用パフォーマンスの比較評価に関する次の記述の空欄（ア）～（ウ）にあてはまる語句または数値の組み合わせとして、最も適切なものはどれか。<br><br>＜資料＞ファンドAとファンドBの過去３年間の運用パフォーマンスに関する情報<br><img src='/img/question/1028_1.png' width='450'/><br><br><div class='b-box'>無リスク金利を１.０％として、＜資料＞の数値によりファンドＡのシャープレシオの値を算出すると（ ア ）となり、同様に算出したファンドＢのシャープレシオの値は（ イ ）となる。両ファンドの運用パフォーマンスを比較すると、過去３年間は（ ウ ）の方が効率的な運用であったと判断される。<br></div>",
        "choice1": "（ア）０.９５ （イ）０.６９ （ウ）ファンドＡ",
        "choice2": "（ア）０.９５ （イ）０.６９ （ウ）ファンドＢ",
        "choice3": "（ア）０.８０ （イ）０.６０ （ウ）ファンドＡ",
        "choice4": "（ア）０.８０ （イ）０.６０ （ウ）ファンドＢ",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1029,
        "shikenId": 20,
        "parentNo": 1,
        "no": 29,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "わが国における個人による金融商品取引に係るセーフティネットに関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "確定拠出年金の加入者が運用の方法として選択した定期預金は、加入者の預金として、預金保険制度による保護の対象となる。",
        "choice2": "日本国内で事業を行う生命保険会社が破綻した場合、生命保険契約者保護機構による補償の対象となる保険契約については、高予定利率契約を除き、原則として、破綻時点の責任準備金等の８０％まで補償される。",
        "choice3": "証券会社が破綻し、分別管理が適切に行われていなかったために、一般顧客の資産の一部または全部が返還されない事態が生じた場合、日本投資者保護基金により、補償対象債権に係る顧客資産について一般顧客１人当たり１,０００万円を上限として補償される。",
        "choice4": "銀行で購入した投資信託は、日本投資者保護基金による保護の対象とならない。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1030,
        "shikenId": 20,
        "parentNo": 1,
        "no": 30,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "金融商品の取引等に係る各種法令に関する次の記述のうち、最も適切なものはどれか。なお、本問においては、「金融サービスの提供及び利用環境の整備等に関する法律」を金融サービス提供法という。",
        "choice1": "金融サービス提供法において、金融サービス仲介業の登録を受けた事業者は、銀行業・金融商品取引業・保険業・貸金業に係る金融サービスのうち、顧客に対し高度に専門的な説明を必要とする金融サービスを仲介することが認められている。",
        "choice2": "金融商品取引法において、金融商品取引業者等が顧客と金融商品取引契約を締結するときは、原則として、契約後すみやかに重要事項を記載した契約締結前交付書面を交付することが義務付けられている。",
        "choice3": "大阪取引所における金、白金などのコモディティを対象とした市場デリバティブ取引は、金融商品取引法の適用対象とならない。",
        "choice4": "消費者契約法において、消費者が事業者の一定の行為により誤認または困惑し、それによって消費者契約の申込みまたは承諾の意思表示をしたときは、消費者はこれを取り消すことができるとされている。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1031,
        "shikenId": 20,
        "parentNo": 1,
        "no": 31,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "所得税の基本的な仕組みに関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "所得税では、原則として、納税者本人の申告により納付すべき税額が確定し、この確定した税額を納付する申告納税制度が採用されている。",
        "choice2": "所得税の納税義務を負うのは居住者のみであり、非居住者が所得税の納税義務を負うことはない。",
        "choice3": "所得税では、課税対象となる所得を１０種類に区分し、それぞれの所得の種類ごとに定められた計算方法により所得の金額を計算する。",
        "choice4": "所得税額の計算において課税総所得金額に乗じる税率は、課税総所得金額が大きくなるにつれて段階的に税率が高くなる超過累進税率が採用されている。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1032,
        "shikenId": 20,
        "parentNo": 1,
        "no": 32,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "所得税における各種所得に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "老齢基礎年金の受給者の公的年金等に係る雑所得以外の所得に係る合計所得金額が１,０００万円を超える場合、雑所得の金額の計算上、老齢基礎年金に係る収入金額から公的年金等控除額は控除されない。",
        "choice2": "不動産の貸付けをしたことに伴い敷金の名目により収受した金銭の額のうち、その全部または一部について、返還を要しないことが確定した金額は、その確定した日の属する年分の不動産所得の金額の計算上、総収入金額に算入する。",
        "choice3": "退職一時金を受け取った退職者が、「退職所得の受給に関する申告書」を提出していない場合、所得税および復興特別所得税として、退職一時金の支給額の２０.４２％が源泉徴収される。",
        "choice4": "為替予約を締結していない外貨定期預金を満期時に円貨で払い戻した結果生じた為替差益は、雑所得として総合課税の対象となる。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1033,
        "shikenId": 20,
        "parentNo": 1,
        "no": 33,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "所得税における各種所得の金額の計算上生じた次の損失の金額のうち、給与所得の金額と損益通算することができるものはどれか。",
        "choice1": "上場株式等に係る譲渡所得の金額の計算上生じた損失の金額",
        "choice2": "不動産所得の金額の計算上生じた損失の金額のうち、不動産所得を生ずべき業務の用に供する土地の取得に要した負債の利子の額に相当する部分の金額",
        "choice3": "公的年金等以外の雑所得の金額の計算上生じた損失の金額",
        "choice4": "物品販売業による事業所得の金額の計算上生じた損失の金額",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1034,
        "shikenId": 20,
        "parentNo": 1,
        "no": 34,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "所得税における所得控除等に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "納税者の合計所得金額が２,４００万円以下である場合、基礎控除の額は４８万円である。",
        "choice2": "合計所得金額が９００万円以下の納税者と生計を一にする配偶者（青色申告者の事業専従者として給与の支払いを受ける人および白色申告者の事業専従者である人を除く）の合計所得金額が４８万円以下の場合、納税者が適用を受けることができる配偶者控除の額は３８万円である。",
        "choice3": "控除対象扶養親族のうち、その年の１２月３１日時点の年齢が１９歳以上２３歳未満の者を特定扶養親族といい、その者に係る扶養控除の額は５８万円である。",
        "choice4": "給与所得控除額は、給与等の収入金額に応じて計算されるが、収入金額が８５０万円を超える場合は１９５万円となる。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1035,
        "shikenId": 20,
        "parentNo": 1,
        "no": 35,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "所得税の青色申告に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "青色申告の適用を受けることができる者は、不動産所得、事業所得、山林所得を生ずべき業務を行う者で、納税地の所轄税務署長の承認を受けた者である。",
        "choice2": "前年からすでに業務を行っている者が、本年分から新たに青色申告の適用を受けようとする場合、その承認を受けようとする年の３月３１日までに「青色申告承認申請書」を納税地の所轄税務署長に提出しなければならない。",
        "choice3": "青色申告を取りやめようとする者は、その年の翌年３月１５日までに「青色申告の取りやめ届出書」を納税地の所轄税務署長に提出しなければならない。",
        "choice4": "前年からすでに業務を行っている者が、本年分から新たに青色申告の適用を受けるために青色申告の承認の申請を行ったが、その年の１２月３１日までに、その申請につき承認または却下の処分がなかったときは、その日において承認があったものとみなされる。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1036,
        "shikenId": 20,
        "parentNo": 1,
        "no": 36,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "所得税における住宅借入金等特別控除（以下「住宅ローン控除」という）に関する次の記述のうち、最も適切なものはどれか。なお、記載されたもの以外の要件はすべて満たしているものとする。",
        "choice1": "住宅ローン控除の対象となる家屋は、納税者がもっぱら居住の用に供する家屋に限られ、店舗併用住宅は対象とならない。",
        "choice2": "住宅ローン控除の適用を受けるためには、その対象となる家屋を取得等した日から３ヵ月以内に自己の居住の用に供さなければならない。",
        "choice3": "給与所得者が住宅ローン控除の適用を受けようとする場合、最初の年分については年末調整の対象者であっても確定申告しなければならない。",
        "choice4": "住宅ローン控除の適用を受けていた者が、転勤等のやむを得ない事由により転居したため、取得した住宅を居住の用に供しなくなった場合、翌年以降は当該住居に係る住宅ローン控除の適用は受けることができない。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1037,
        "shikenId": 20,
        "parentNo": 1,
        "no": 37,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "法人税の損金に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "２０１６年４月１日以後に取得した建物附属設備の減価償却方法は、定額法である。",
        "choice2": "役員退職給与を損金の額に算入するためには、所定の時期に確定額を支給する旨の定めの内容に関する届出書をあらかじめ税務署長に提出しなければならない。",
        "choice3": "参加者１人当たり１０,０００円以下の得意先との接待飲食費は、必要とされる書類を保存していれば、その２分の１の額を損金の額に算入することができる。",
        "choice4": "損金の額に算入される租税公課のうち、事業税については、原則として、その事業税に係る納税申告書を提出した日の属する事業年度の損金の額に算入することができない。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1038,
        "shikenId": 20,
        "parentNo": 1,
        "no": 38,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "消費税の簡易課税制度に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "簡易課税制度の適用を受けることができるのは、基準期間における課税売上高が１億円以下の事業者である。",
        "choice2": "新たに事業を開始した事業者は、事業を開始した日の属する課税期間内に、「消費税簡易課税制度選択届出書」を所轄税務署長に提出することで、当該課税期間から簡易課税制度の適用を受けることができる。",
        "choice3": "簡易課税制度を選択した事業者は、事業を廃止した場合を除き、原則として、５年間は簡易課税制度の適用を継続しなければならない。",
        "choice4": "簡易課税制度の選択を取りやめる場合、原則として、その適用を取りやめようとする課税期間の初日から３ヵ月以内に「消費税簡易課税制度選択不適用届出書」を所轄税務署長に提出しなければならない。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1039,
        "shikenId": 20,
        "parentNo": 1,
        "no": 39,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "会社と役員間の取引に係る所得税・法人税に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "役員が会社に無利息で金銭の貸付けを行った場合、原則として、通常収受すべき利息に相当する金額が、その役員の雑所得の収入金額に算入される。",
        "choice2": "会社が所有する建物を適正な時価よりも高い価額で役員に譲渡した場合、その会社の所得の金額の計算上、適正な時価と譲渡対価の差額は、益金の額に算入される。",
        "choice3": "会社が役員の所有する土地を時価未満の価額で譲り受けた場合、時価と譲受対価の差額相当額は、その会社の所得金額の計算上、益金の額に算入される。",
        "choice4": "役員が会社の所有する社宅に無償で居住している場合、原則として、通常の賃料相当額が、その役員の給与所得の収入金額に算入される。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1040,
        "shikenId": 20,
        "parentNo": 1,
        "no": 40,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "決算書の分析に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "当座比率（％）は、「当座資産÷流動負債×１００」の算式で計算される。",
        "choice2": "固定比率（％）は、「固定資産÷自己資本×１００」の算式で計算される。",
        "choice3": "自己資本比率（％）は、「自己資本÷総資産×１００」の算式で計算される。",
        "choice4": "流動比率（％）は、「流動資産÷総資産×１００」の算式で計算される。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1041,
        "shikenId": 20,
        "parentNo": 1,
        "no": 41,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "不動産鑑定評価基準における不動産の価格を求める鑑定評価の手法に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "収益還元法は、文化財の指定を受けた建造物等の一般的に市場性を有しない不動産や賃貸の用に供されていない自用の不動産の価格を求める際には、基本的に適用してはならないとされている。",
        "choice2": "収益還元法のうち直接還元法は、対象不動産の一期間の純収益を還元利回りで還元して対象不動産の価格を求める手法である。",
        "choice3": "原価法は、価格時点における対象不動産の再調達原価を求め、この再調達原価について増加修正を行って対象不動産の価格をもとめる手法である。",
        "choice4": "取引事例比較法では、取引事例の取引時点が価格時点と異なり、その間に価格水準の変動があると認められる場合であっても、当該取引事例の価格は取引時点の価格から修正する必要はないとされている。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1042,
        "shikenId": 20,
        "parentNo": 1,
        "no": 42,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "土地の価格に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "地価公示法による公示価格は、毎年１月１日を標準地の価格判定の基準日としている。",
        "choice2": "都道府県地価調査の基準地の標準価格は、毎年７月１日を価格判定の基準日としている。",
        "choice3": "固定資産税評価額は、原則として、市町村長が固定資産評価基準により決定する。",
        "choice4": "評価替えの基準年度における宅地の固定資産税評価額は、前年の地価公示法による公示価格等の８０％を目途として評定されている。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1043,
        "shikenId": 20,
        "parentNo": 1,
        "no": 43,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "民法および借地借家法に関する次の記述のうち、最も適切なものはどれか。なお、本問においては、借地借家法第３８条における定期建物賃貸借契約を定期借家契約といい、それ以外の建物賃貸借契約を普通借家契約という。また、記載のない特約については考慮しないものとする。",
        "choice1": "賃借人は、建物の引渡しを受けた後の通常の使用および収益によって生じた建物の損耗ならびに経年変化については、賃貸借が終了したときに原状に復する義務を負わない。",
        "choice2": "普通借家契約において、賃借人が賃貸人の同意を得て建物に付加した造作について、賃貸借終了時、賃借人が賃貸人に、その買取りを請求しない旨の特約をした場合、その特約は無効である。",
        "choice3": "定期借家契約を締結するときは、賃貸人は、契約後すみやかに、賃借人に対し、契約の更新がなく、期間満了により賃貸借が終了することについて、その旨を記載した書面を交付し、または、賃借人の承諾を得て当該書面に記載すべき事項を電磁的方法により提供して、説明しなければならない。",
        "choice4": "定期借家契約において、経済事情の変動があっても賃貸借期間中は賃料を増減額しないこととする特約をした場合、その特約は無効である。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1044,
        "shikenId": 20,
        "parentNo": 1,
        "no": 44,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "借地借家法に関する次の記述のうち、最も適切なものはどれか。なお、本問においては、借地借家法第３８条における定期建物賃貸借契約を定期借家契約といい、それ以外の建物賃貸借契約を普通借家契約という。また、特約については考慮しないものとする。",
        "choice1": "普通借家契約において、建物の賃借人による建物の賃貸借の解約の申入れは、賃貸人および賃借人が建物の使用を必要とする事情や建物の利用状況などを考慮して、正当の事由があると認められる場合でなければすることができない。",
        "choice2": "定期借家契約は、建物の賃借人が建物の全部または一部を事業の用に供することを目的とする場合、公正証書によってしなければならない。",
        "choice3": "普通借家契約において、存続期間を１年未満とする建物の賃貸借は、期間の定めがない建物の賃貸借とみなされる。",
        "choice4": "定期借家契約は、契約の更新がなく、期間の満了により建物の賃貸借が終了し、再契約をすることはできない。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1045,
        "shikenId": 20,
        "parentNo": 1,
        "no": 45,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "都市計画法に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "都市計画区域内において、用途地域が定められている区域については、防火地域または準防火地域のいずれかを定めなければならない。",
        "choice2": "三大都市圏の一定の区域や一定の大都市の都市計画区域においては、都市計画に市街化区域と市街化調整区域との区分を定めるものとされている。",
        "choice3": "市街化区域および市街化調整区域については、原則として用途地域を定めるものとされている。",
        "choice4": "市街化区域は、すでに市街地を形成している区域およびおおむね５年以内に優先的かつ計画的に市街化を図るべき区域とされている。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1046,
        "shikenId": 20,
        "parentNo": 1,
        "no": 46,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "建物の区分所有等に関する法律に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "区分所有建物のうち、構造上の独立性と利用上の独立性を備えた部分は、区分所有権の目的となる専有部分の対象となり、規約により共用部分とすることはできない。",
        "choice2": "規約を変更するためには、区分所有者および議決権の各４分の３以上の多数による集会の決議が必要となる。",
        "choice3": "区分所有者は、敷地利用権が数人で有する所有権である場合、原則として、その有する専有部分とその専有部分に係る敷地利用権とを分離して処分することはできない。",
        "choice4": "区分所有者以外の専有部分の占有者は、建物またはその敷地もしくは附属施設の使用方法について、区分所有者が規約または集会の決議に基づいて負う義務と同一の義務を負う。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1047,
        "shikenId": 20,
        "parentNo": 1,
        "no": 47,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "不動産の取得等に係る税金に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "所定の要件を満たす戸建て住宅（認定長期優良住宅を除く）を新築した場合、不動産取得税の課税標準の算定に当たっては、１戸につき最高１,２００万円を価格から控除することができる。",
        "choice2": "不動産に抵当権設定登記をする際の登録免許税の課税標準は、債権金額（根抵当権の場合は極度金額）である。",
        "choice3": "土地の所有権を等価交換方式による全部譲渡により取得した場合は、原則として、取得者に対して不動産取得税は課されない。",
        "choice4": "不動産の所有権移転登記をする際の登録免許税の税率は、登記原因が贈与による場合の方が相続による場合に比べて高くなる。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1048,
        "shikenId": 20,
        "parentNo": 1,
        "no": 48,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "個人が土地を譲渡した場合の譲渡所得に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "相続人が相続により取得した土地を譲渡した場合、その土地の所有期間を判定する際の取得の日は、相続人が当該相続を登記原因として所有権移転登記をした日である。",
        "choice2": "土地の譲渡が短期譲渡所得に区分される場合、課税短期譲渡所得に対して、原則として、所得税（復興特別所得税を含む）１５.３１５％、住民税５％の税率により課税される。",
        "choice3": "土地を譲渡する際に支出した仲介手数料は、譲渡所得の金額の計算上、譲渡費用に含まれない。",
        "choice4": "譲渡所得の金額の計算上、譲渡した土地の取得費が不明な場合には、譲渡収入金額の５％相当額を取得費とすることができる。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1049,
        "shikenId": 20,
        "parentNo": 1,
        "no": 49,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "居住用財産を譲渡した場合の３,０００万円の特別控除（以下「３,０００万円特別控除」という）および居住用財産を譲渡した場合の長期譲渡所得の課税の特例（以下「軽減税率の特例」という）に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "３,０００万円特別控除は、居住用財産で居住の用に供さなくなったものを譲渡する場合、居住の用に供さなくなった日から３年を経過した日の属する年の翌年の１２月３１日までに譲渡しなければ、適用を受けることができない。",
        "choice2": "軽減税率の特例は、譲渡した日の属する年の１月１日における居住用財産の所有期間が２０年を超えていなければ、適用を受けることができない。",
        "choice3": "３,０００万円特別控除は、居住用財産を配偶者に譲渡した場合には適用を受けることができない。",
        "choice4": "３,０００万円特別控除と軽減税率の特例は、同一の居住用財産の譲渡について、重複して適用を受けることができる。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1050,
        "shikenId": 20,
        "parentNo": 1,
        "no": 50,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "不動産の有効活用の手法の一般的な特徴に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "等価交換方式における全部譲渡方式は、土地所有者がいったん土地の全部をデベロッパーに譲渡し、その対価としてその土地上にデベロッパーが建設した建物およびその土地の一部を譲り受ける方式である。",
        "choice2": "建設協力金方式は、土地所有者が、建設する建物を貸し付ける予定のテナント等に建設資金の全部または一部を貸し付け、当該テナント等がビルや店舗等を建設する方式である。",
        "choice3": "定期借地権方式では、土地所有者が自己の土地上に建設される建物の所有名義人となり、当該土地と建物を一定期間貸し付けることにより地代・賃料収入を得ることができる。",
        "choice4": "事業受託方式は、土地の有効活用の企画、建設会社の選定、建設資金の調達等をデベロッパーに任せ、当該土地上に建設された建物の管理・運営等は土地所有者が行う方式である。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1051,
        "shikenId": 20,
        "parentNo": 1,
        "no": 51,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "民法上の贈与に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "書面によらない贈与は、その履行の終わった部分については、各当事者が解除をすることができない。",
        "choice2": "定期贈与とは、贈与者が受贈者に対して定期的に財産を給付することを目的とする贈与をいい、贈与者または受贈者のいずれか一方の死亡によって、その効力を失う。",
        "choice3": "死因贈与では、民法の遺贈に関する規定が準用され、贈与者のみの意思表示により成立し、贈与者の死亡によって効力が生じる。",
        "choice4": "負担付贈与では、受贈者がその負担である義務を履行しない場合において、贈与者が相当の期間を定めてその履行の催告をし、その期間内に履行がない場合、原則として、贈与者は、当該贈与の契約の解除をすることができる。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1052,
        "shikenId": 20,
        "parentNo": 1,
        "no": 52,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "相続人が次の（ア）～（ウ）である場合、民法上、それぞれの場合における被相続人の配偶者の法定相続分の組み合わせとして、最も適切なものはどれか。<br><br>（ア）被相続人の配偶者および子の合計２人<br>（イ）被相続人の配偶者および母の合計２人<br>（ウ）被相続人の配偶者および兄の合計２人",
        "choice1": "（ア）１／２（イ）１／３（ウ）１／４",
        "choice2": "（ア）１／２（イ）２／３（ウ）３／４",
        "choice3": "（ア）３／４（イ）２／３（ウ）１／２",
        "choice4": "（ア）１／３（イ）２／３（ウ）３／４",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1053,
        "shikenId": 20,
        "parentNo": 1,
        "no": 53,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "贈与税の申告と納付に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "国税電子申告・納税システム（ｅ－Ｔａｘ）は、贈与税の申告には対応していない。",
        "choice2": "贈与税の申告書は、原則として、贈与を受けた年の翌年２月１日から３月１５日までの間に、受贈者の納税地の所轄税務署長に提出しなければならない。",
        "choice3": "贈与税を納期限までに納付することが困難である場合、その納付を困難とする金額を限度として延納を申請することができる。",
        "choice4": "贈与税の納付について認められる延納期間は、最長５年である。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1054,
        "shikenId": 20,
        "parentNo": 1,
        "no": 54,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "民法上の相続人等に関する次の記述のうち、最も不適切なものはどれか。なお、記載のない事項については考慮しないものとする。",
        "choice1": "特別養子縁組による養子は、実方の父母の相続人とならない。",
        "choice2": "被相続人の子が廃除により相続権を失った場合、その者に被相続人の直系卑属である子がいるときは、その子（被相続人の孫）は代襲相続人となる。",
        "choice3": "離婚した元配偶者との間に出生した被相続人の子が当該元配偶者の親権に服している場合、その子は相続人とならない。",
        "choice4": "被相続人と婚姻の届出をしておらず、被相続人といわゆる内縁関係にあった者は、被相続人の配偶者とはみなされず相続人とならない。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1055,
        "shikenId": 20,
        "parentNo": 1,
        "no": 55,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "民法に規定する相続の承認および放棄に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "被相続人の子が相続の放棄をした場合、その相続の放棄をした者の子（被相続人の孫）は、代襲相続人とならない。",
        "choice2": "契約者（＝保険料負担者）および被保険者を被相続人とする生命保険契約の死亡保険金受取人に指定されていた相続人が、被相続人の死亡により死亡保険金を受け取った場合、その相続について単純承認をしたものとみなされる。",
        "choice3": "単純承認とは、相続人が受け継いだ資産（積極財産）の範囲内で負債（消極財産）を支払い、積極財産を超える消極財産については責任を負わないという相続の方法をいう。",
        "choice4": "相続の放棄をしようとする者は、原則として、相続の開始があったことを知った時から１ヵ月以内に、家庭裁判所に相続の放棄をする旨を申述しなければならない。",
        "choice5": "",
        "answer": 1,
        "score": 1,
        "image": ""
      },
      {
        "id": 1056,
        "shikenId": 20,
        "parentNo": 1,
        "no": 56,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "相続税における宅地の評価に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "二方面に路線がある角地を路線価方式によって評価する場合、それぞれの路線価に奥行価格補正率を乗じた価額を比較し、高い方の路線価が正面路線価となる。",
        "choice2": "宅地の価額は、その宅地が登記上は２筆の宅地であっても一体として利用している場合は、その２筆の宅地全体を１画地として評価する。",
        "choice3": "宅地の評価方法には、路線価方式と倍率方式とがあり、いずれを採用するかについては、宅地の所在地により各国税局長が指定している。",
        "choice4": "倍率方式によって評価する宅地が不整形地である場合の価額は、原則として、その宅地の固定資産税評価額に一定倍率を乗じた価額に宅地の形状に応じた補正率を乗じて算出する。",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
      {
        "id": 1057,
        "shikenId": 20,
        "parentNo": 1,
        "no": 57,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "小規模宅地等についての相続税の課税価格の計算の特例に関する次の空欄（ア）～（エ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、宅地等の適用面積の調整は考慮しないものとする。<br><br><img src='/img/question/1057_1.png' width='520'>",
        "choice1": "（ア）５０％ （イ）３３０㎡ （ウ）４００㎡ （エ）５０％",
        "choice2": "（ア）５０％ （イ）４００㎡ （ウ）２００㎡ （エ）８０％",
        "choice3": "（ア）８０％ （イ）３３０㎡ （ウ）４００㎡ （エ）５０％",
        "choice4": "（ア）８０％ （イ）４００㎡ （ウ）２００㎡ （エ）８０％",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1058,
        "shikenId": 20,
        "parentNo": 1,
        "no": 58,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "直系尊属から住宅取得等資金の贈与を受けた場合の贈与税の非課税の特例（以下「本特例」という）に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "受贈者の配偶者の父母（義父母）から住宅取得資金の贈与を受けた場合、本特例の適用を受けることができる。",
        "choice2": "住宅取得資金の贈与者が死亡した場合において、その相続人が贈与を受けた住宅取得資金のうち、本特例の適用を受けて贈与税が非課税とされた金額については、その贈与が暦年課税または相続時精算課税制度のいずれの適用を受けていたとしても、相続税の課税価格に加算されない。",
        "choice3": "受贈者が自己の居住の用に供する家屋とともにその敷地の用に供される土地を取得する場合において、その土地の取得の対価に充てるための金銭については、本特例の適用を受けることができない。",
        "choice4": "新築した家屋が店舗併用住宅である場合、その家屋の新築の対価に充てるための金銭については、本特例の適用を受けることができない。",
        "choice5": "",
        "answer": 2,
        "score": 1,
        "image": ""
      },
      {
        "id": 1059,
        "shikenId": 20,
        "parentNo": 1,
        "no": 59,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "Ａさんの相続が開始した場合の相続税額の計算における土地の評価に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "Ａさんが、自己が所有する土地の上に自宅を建築して居住していた場合、この土地は自用地として評価する。",
        "choice2": "Ａさんが、自己が所有する土地に建物の所有を目的とする賃借権を設定し、借地人がこの土地の上に自宅を建築して居住していた場合、この土地は貸宅地として評価する。",
        "choice3": "Ａさんの子が、Ａさんが所有する土地を使用貸借で借り受け、自宅を建築して居住していた場合、この土地は貸宅地として評価する。",
        "choice4": "Ａさんが、自己が所有する土地の上に店舗用建物を建築し、当該建物を第三者に賃貸していた場合、この土地は貸家建付地として評価する。",
        "choice5": "",
        "answer": 3,
        "score": 1,
        "image": ""
      },
      {
        "id": 1060,
        "shikenId": 20,
        "parentNo": 1,
        "no": 60,
        "groupId": 1,
        "answerType": 1,
        "inputType": 1,
        "question1":  "",
        "question2": "法人成り等に関する次の記述の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><br><div class='b-box'>個人事業の場合、通常、利益は事業所得として他の所得と合算されて最高（ア）％の超過累進税率による所得税の課税対象となるが、個人事業の法人成りにより、法人に課される法人税は、原則として、比例税率となる。なお、資本金の額が１億円以下の法人（適用除外事業者を除く）に対する法人税の税率は、軽減措置が適用される。２０１９年４月１日以後に開始する事業年度において、年８００万円以下の所得金額からなる部分の金額については（イ）％とされ、年８００万円超の所得金額からなる部分の金額については（ウ）％とされる。<br></div>",
        "choice1": "（ア）５０（イ）１９.０（ウ）１５.０",
        "choice2": "（ア）５０（イ）１５.０（ウ）１９.０",
        "choice3": "（ア）４５（イ）２３.２（ウ）１５.０",
        "choice4": "（ア）４５（イ）１５.０（ウ）２３.２",
        "choice5": "",
        "answer": 4,
        "score": 1,
        "image": ""
      },
	  {
        "id": 1061,
        "shikenId": 20,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 2,
        "inputType": 7,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、遺族基礎年金について説明した。Ｍさんが説明した以下の文章の空欄①〜④に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を選びなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img class='question-img' src='/img/question/1061_2.png'>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ|ル",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "ハ,ト,ホ,ル",
        "score": "1,1,1,1",
        "image": "1061.png"
    },
    {
        "id": 1062,
        "shikenId": 20,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 2,
        "inputType": 5,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、遺族厚生年金について説明した。Ｍさんが説明した以下の文章の空欄①〜③に入る最も適切な数値を記入しなさい。なお、年金額は2024年度価額に基づいて計算し、年金額の端数処理は円未満を四捨五入すること。<br><img class='question-img' src='/img/question/1061_3.png'>",
        "choice1": "月",
        "choice2": "円",
        "choice3": "歳",
        "choice4": "",
        "choice5": "",
        "answer": "300,551650,65",
        "score": "1,1,1",
        "image": "1061.png"
    },
    {
        "id": 1063,
        "shikenId": 20,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、公的介護保険（以下、「介護保険」という）の保険給付等について説明した。Ｍさんが説明した次の記述①〜③について、適切なものには○印を、不適切なものには×印を選びなさい。<br><img class='question-img' src='/img/question/1061_4.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,2,1",
        "score": "1,1,1",
        "image": "1061.png"
    },
    {
        "id": 1064,
        "shikenId": 20,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 2,
        "inputType": 5,
        "question1": "",
        "question2": "《設例》のデータに基づいて算出される次の①、②を求めなさい（計算過程の記載は不要）。〈答〉は表示単位の小数点以下第 ３ 位を四捨五入し、小数点以下第 ２ 位までを解答すること。<br><br>① Ｘ社のＲＯＥ<br>② Ｘ社のＰＢＲ",
        "choice1": "％",
        "choice2": "倍",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "4.44,1.11",
        "score": "2,2",
        "image": "1064.png"
    },
    {
        "id": 1065,
        "shikenId": 20,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、《設例》のデータに基づいて、株式の投資指標等について説明した。Ｍさんが説明した次の記述①〜③について、適切なものには○印を、不適切なものには×印を選びなさい。<br><img class='question-img' src='/img/question/1064_1.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,1,2",
        "score": "1,1,1",
        "image": "1064.png"
    },
    {
        "id": 1066,
        "shikenId": 20,
        "parentNo": 1,
        "no": 6,
        "groupId": 2,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、2024年から始まった新しいＮＩＳＡについて説明した。Ｍさんが説明した次の記述①〜③について、適切なものには○印を、不適切なものには×印を選びなさい。<br><img class='question-img' src='/img/question/1064_2.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,2",
        "score": "1,1,1",
        "image": "1064.png"
    },
    {
        "id": 1067,
        "shikenId": 20,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 2,
        "inputType": 5,
        "question1": "",
        "question2": "ＡさんがＸ社から受け取った退職金に係る退職所得の金額を計算した下記の計算式の空欄①〜③に入る最も適切な数値を記入しなさい。なお、Ａさんは、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。また、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img class='question-img' src='/img/question/1067_1.png'>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "",
        "answer": "800,2060,470",
        "score": "1,1,1",
        "image": "1067.png"
    },
    {
        "id": 1068,
        "shikenId": 20,
        "parentNo": 1,
        "no": 8,
        "groupId": 3,
        "answerType": 2,
        "inputType": 5,
        "question1": "",
        "question2": "Ａさんの2024年分の所得金額について、次の①、②を求めなさい（計算過程の記載は不要）。なお、総所得金額の計算上、Ａさんが所得金額調整控除の適用対象者に該当している場合、所得金額調整控除額を控除すること。また、〈答〉は万円単位とすること。<br><br>① 総所得金額に算入される一時所得の金額<br>② 総所得金額<br><br><img class='question-img' src='/img/question/1067_2.png'>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "50,540",
        "score": "2,2",
        "image": "1067.png"
    },
    {
        "id": 1069,
        "shikenId": 20,
        "parentNo": 1,
        "no": 9,
        "groupId": 3,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する次の記述①〜③について、適切なものには○印を、不適切なものには×印を選びなさい。<br><br>① 「Ａさんが適用を受けることができる配偶者控除の額は、48万円です」<br>② 「Ａさんが適用を受けることができる長男Ｃさんに係る扶養控除の額は、63万円です」<br>③ 「Ａさんが適用を受けることができる母Ｄさんに係る扶養控除の額は、38万円です」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,2,2",
        "score": "1,1,1",
        "image": "1067.png"
    },
    {
        "id": 1070,
        "shikenId": 20,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 2,
        "inputType": 5,
        "question1": "",
        "question2": "甲土地に準耐火建築物を建築する場合における次の①、②を求めなさい（計算過程の記載は不要）。<br><br>① 建蔽率の上限となる建築面積<br>② 容積率の上限となる延べ面積",
        "choice1": "㎡",
        "choice2": "㎡",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "320,1200",
        "score": "2,2",
        "image": "1071.png"
    },
    {
        "id": 1071,
        "shikenId": 20,
        "parentNo": 1,
        "no": 11,
        "groupId": 4,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "自己建設方式による甲土地の有効活用に関する次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1071_1.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,1,2",
        "score": "1,1,1",
        "image": "1071.png"
    },
    {
        "id": 1072,
        "shikenId": 20,
        "parentNo": 1,
        "no": 12,
        "groupId": 4,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "Ａさんが甲土地に自己建設方式によって賃貸マンションを建築した場合における賃貸事業開始後の相続税評価額に関する次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1071_2.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,1",
        "score": "1,1,1",
        "image": "1071.png"
    },
    {
        "id": 1073,
        "shikenId": 20,
        "parentNo": 1,
        "no": 13,
        "groupId": 5,
        "answerType": 2,
        "inputType": 7,
        "question1": "",
        "question2": "Ａさんの相続等に関する以下の文章の空欄①〜③に入る最も適切な語句を、下記の〈語句群〉のイ〜ヌのなかから選び、その記号を記入しなさい。<br><br><img class='question-img' src='/img/question/1074_1.png'>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "イ,リ,ホ",
        "score": "1,1,1",
        "image": "1074.png"
    },
    {
        "id": 1074,
        "shikenId": 20,
        "parentNo": 1,
        "no": 14,
        "groupId": 5,
        "answerType": 2,
        "inputType": 6,
        "question1": "",
        "question2": "生前贈与に関する次の記述①〜④について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1074_2.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,2,2",
        "score": "1,1,1,1",
        "image": "1074.png"
    },
    {
        "id": 1075,
        "shikenId": 20,
        "parentNo": 1,
        "no": 15,
        "groupId": 5,
        "answerType": 2,
        "inputType": 5,
        "question1": "",
        "question2": "現時点（2024年10月 １ 日）において、Ａさんに相続が開始した場合における相続税の総額を試算した下記の表の空欄①〜③に入る最も適切な数値を求めなさい。なお、相続税の課税価格の合計額は １ 億6, 000万円とし、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><br><img class='question-img' src='/img/question/1074_3.png'>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "",
        "answer": "4200,1070,2140",
        "score": "1,1,1",
        "image": "1074.png"
    },
	{
        "id": 1076,
        "shikenId": 20,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 3,
        "inputType": 5,
        "question1": "",
        "question2": "Ａさんが、原則として、65歳から受給することができる老齢基礎年金および老齢厚生年金の年金額（2024年度価額）を計算した次の〈計算の手順〉の空欄①〜④に入る最も適切な数値を記入しなさい。計算にあたっては、《設例》の〈Ａさんとその家族に関する資料〉および下記の〈資料〉に基づくこと。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><br><img class='question-img' src='/img/question/1076_1.png'>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "円",
        "choice5": "",
        "answer": "759900,1113710,447,1114157",
        "score": "1,1,1,1",
        "image": "1076.png"
    },
    {
        "id": 1077,
        "shikenId": 20,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさん夫婦に対して、公的年金制度からの老齢給付について説明した。Ｍさんが説明した次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1076_2.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,1,2",
        "score": "1,1,1",
        "image": "1076.png"
    },
    {
        "id": 1078,
        "shikenId": 20,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、定年退職後の社会保険の各種取扱い等について説明した。Ｍさんが説明した次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1076_3.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,1,2",
        "score": "1,1,1",
        "image": "1076.png"
    },
    {
        "id": 1079,
        "shikenId": 20,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 3,
        "inputType": 7,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、下記の〈前提〉においてＡさんが死亡した場合、妻Ｂさんが受給することができる公的年金制度からの遺族給付について説明した。Ｍさんが説明した以下の文章の空欄①〜③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選びなさい。<br><br><img class='question-img' src='/img/question/1079_1.png'>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "ロ,ヘ,リ",
        "score": "1,1,1",
        "image": "1079.png"
    },
    {
        "id": 1080,
        "shikenId": 20,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 3,
        "inputType": 5,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、必要保障額およびＡさんが提案を受けた生命保険の死亡保障の額について説明した。Ｍさんが説明した以下の文章の空欄①、②に入る最も適切な数値を記入しなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><br><img class='question-img' src='/img/question/1079_2.png'>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "4524,5000",
        "score": "1,2",
        "image": "1079.png"
    },
    {
        "id": 1081,
        "shikenId": 20,
        "parentNo": 1,
        "no": 6,
        "groupId": 2,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、Ａさんが提案を受けた生命保険の保障内容および課税関係について説明した。Ｍさんが説明した次の記述①〜④について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1079_3.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,2,2,1",
        "score": "1,1,1,1",
        "image": "1079.png"
    },
    {
        "id": 1082,
        "shikenId": 20,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 3,
        "inputType": 5,
        "question1": "",
        "question2": "仮に、Ｘ社がＡさんに役員退職金6, 500万円を支給した場合、Ａさんが受け取る役員退職金について、次の①、②を求めなさい（計算過程の記載は不要）。〈答〉は万円単位とすること。なお、Ａさんの役員在任期間（勤続年数）を３９年 ９ カ月とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。<br><br>① 退職所得控除額<br>② 退職所得の金額",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2200,2150",
        "score": "1,2",
        "image": "1082.png"
    },
    {
        "id": 1083,
        "shikenId": 20,
        "parentNo": 1,
        "no": 8,
        "groupId": 3,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、Ａさんに対して、《設例》の〈資料 １ 〉の無配当逓増定期保険について説明した。Ｍさんが説明した次の記述①〜④について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1082_1.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,2,2",
        "score": "1,1,1,1",
        "image": "1082.png"
    },
    {
        "id": 1084,
        "shikenId": 20,
        "parentNo": 1,
        "no": 9,
        "groupId": 3,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ｍさんは、長男Ｂさんに対して、《設例》の〈資料２ 〉の無配当低解約返戻金型終身保険について説明した。Ｍさんが説明した次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1082_2.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,1,2",
        "score": "1,1,1",
        "image": "1082.png"
    },
    {
        "id": 1085,
        "shikenId": 20,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税の課税に関する次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1085_1.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,1",
        "score": "1,1,1",
        "image": "1086.png"
    },
    {
        "id": 1086,
        "shikenId": 20,
        "parentNo": 1,
        "no": 11,
        "groupId": 4,
        "answerType": 3,
        "inputType": 7,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における配偶者控除に関する以下の文章の空欄①〜③に入る最も適切な数値を、下記の〈数値群〉のなかから選び、その記号を記入しなさい。<br><br><img class='question-img' src='/img/question/1085_2.png'>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "ト,イ,チ",
        "score": "1,1,1",
        "image": "1086.png"
    },
    {
        "id": 1087,
        "shikenId": 20,
        "parentNo": 1,
        "no": 12,
        "groupId": 4,
        "answerType": 3,
        "inputType": 5,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税の算出税額を計算した下記の表の空欄①〜④に入る最も適切な数値を求めなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><br><img class='question-img' src='/img/question/1085_3.png'>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "円",
        "choice5": "",
        "answer": "8000000,380000,480000,532500",
        "score": "1,1,1,1",
        "image": "1086.png"
    },
    {
        "id": 1088,
        "shikenId": 20,
        "parentNo": 1,
        "no": 13,
        "groupId": 5,
        "answerType": 3,
        "inputType": 7,
        "question1": "",
        "question2": "Ｘ社株式に関する以下の文章の空欄①〜③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選びなさい。<br><br><img class='question-img' src='/img/question/1088_1.png'>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "ニ,ト,チ",
        "score": "1,1,1",
        "image": "1088.png"
    },
    {
        "id": 1089,
        "shikenId": 20,
        "parentNo": 1,
        "no": 14,
        "groupId": 5,
        "answerType": 3,
        "inputType": 6,
        "question1": "",
        "question2": "Ａさんの相続等に関する次の記述①〜③について、適切なものには○印を、不適切なものには×印を記入しなさい。<br><br><img class='question-img' src='/img/question/1088_2.png'>",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,2",
        "score": "1,1,1",
        "image": "1088.png"
    },
    {
        "id": 1090,
        "shikenId": 20,
        "parentNo": 1,
        "no": 15,
        "groupId": 5,
        "answerType": 3,
        "inputType": 5,
        "question1": "",
        "question2": "現時点（2025年 １ 月 １ 日）において、Ａさんの相続が開始した場合における相続税の総額を試算した下記の表の空欄①〜④に入る最も適切な数値を求めなさい。なお、相続税の課税価格の合計額は４ 億2, 000万円とし、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><br><img class='question-img' src='/img/question/1088_3.png'>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "万円",
        "choice5": "",
        "answer": "4800,5740,2090,9920",
        "score": "1,1,1,1",
        "image": "1088.png"
    },
	{
        "id": 1100,
        "shikenId": 20,
        "parentNo": 2,
        "no": 1,
        "groupId": 1,
        "answerType": 4,
        "inputType": 9,
        "question1": "<img src=\"/img/question/fp2q/1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,1,2,2",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1101,
        "shikenId": 20,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/2.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "3",
        "score": "1",
        "image": ""
    },
    {
        "id": 1102,
        "shikenId": 20,
        "parentNo": 2,
        "no": 3,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/3.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,4,3,3",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1103,
        "shikenId": 20,
        "parentNo": 2,
        "no": 4,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/4.png\" class=\"question-img\" /><br><img src=\"/img/question/fp2q/5.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "6120",
        "score": "2",
        "image": ""
    },
    {
        "id": 1104,
        "shikenId": 20,
        "parentNo": 2,
        "no": 5,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/6.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "446",
        "score": "1",
        "image": ""
    },
    {
        "id": 1105,
        "shikenId": 20,
        "parentNo": 2,
        "no": 6,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/7.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "3315000",
        "score": "1",
        "image": ""
    },
    {
        "id": 1106,
        "shikenId": 20,
        "parentNo": 2,
        "no": 7,
        "groupId": 1,
        "answerType": 4,
        "inputType": 9,
        "question1": "<img src=\"/img/question/fp2q/8.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,2,1,2",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1107,
        "shikenId": 20,
        "parentNo": 2,
        "no": 8,
        "groupId": 1,
        "answerType": 4,
        "inputType": 9,
        "question1": "<img src=\"/img/question/fp2q/9.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,2,2",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1108,
        "shikenId": 20,
        "parentNo": 1,
        "no": 9,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/10.png\" class=\"question-img\" /><br><img src=\"/img/question/fp2q/11.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "1",
        "score": "1",
        "image": ""
    },
    {
        "id": 1109,
        "shikenId": 20,
        "parentNo": 1,
        "no": 10,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/12.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "3",
        "score": "1",
        "image": ""
    },
    {
        "id": 1110,
        "shikenId": 20,
        "parentNo": 2,
        "no": 11,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/13.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "900,270",
        "score": "1,1",
        "image": ""
    },
    {
        "id": 1111,
        "shikenId": 20,
        "parentNo": 2,
        "no": 12,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/14.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "24000",
        "score": "2",
        "image": ""
    },
    {
        "id": 1112,
        "shikenId": 20,
        "parentNo": 2,
        "no": 13,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/15.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "%",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "0.533",
        "score": "2",
        "image": ""
    },
    {
        "id": 1113,
        "shikenId": 20,
        "parentNo": 1,
        "no": 14,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/16.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "2",
        "score": "2",
        "image": ""
    },
    {
        "id": 1114,
        "shikenId": 20,
        "parentNo": 2,
        "no": 15,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/17.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8|9|10",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "4,6,1,8",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1115,
        "shikenId": 20,
        "parentNo": 1,
        "no": 16,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/18.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "2",
        "score": "2",
        "image": ""
    },
    {
        "id": 1116,
        "shikenId": 20,
        "parentNo": 2,
        "no": 17,
        "groupId": 1,
        "answerType": 4,
        "inputType": 9,
        "question1": "<img src=\"/img/question/fp2q/19.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,1,1",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1117,
        "shikenId": 20,
        "parentNo": 2,
        "no": 18,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/20.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "㎡",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "110.4",
        "score": "2",
        "image": ""
    },
    {
        "id": 1118,
        "shikenId": 20,
        "parentNo": 1,
        "no": 19,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/21.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "2",
        "score": "1",
        "image": ""
    },
    {
        "id": 1119,
        "shikenId": 20,
        "parentNo": 2,
        "no": 20,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/22.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4|5|6",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,1,6,5",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1120,
        "shikenId": 20,
        "parentNo": 1,
        "no": 21,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/23.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "2",
        "score": "2",
        "image": ""
    },
    {
        "id": 1121,
        "shikenId": 20,
        "parentNo": 1,
        "no": 22,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/24.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "3",
        "score": "2",
        "image": ""
    },
    {
        "id": 1122,
        "shikenId": 20,
        "parentNo": 1,
        "no": 23,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/25.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "4",
        "score": "2",
        "image": ""
    },
    {
        "id": 1123,
        "shikenId": 20,
        "parentNo": 1,
        "no": 24,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/26.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "4",
        "score": "1",
        "image": ""
    },
    {
        "id": 1124,
        "shikenId": 20,
        "parentNo": 2,
        "no": 25,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/27.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8|9|10|11|12",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,6,8,12",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1125,
        "shikenId": 20,
        "parentNo": 2,
        "no": 26,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/28.png\" class=\"question-img\" /><br><img src=\"/img/question/fp2q/29.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "",
        "answer": "11,1266,4310",
        "score": "2,2,2",
        "image": ""
    },
    {
        "id": 1126,
        "shikenId": 20,
        "parentNo": 2,
        "no": 27,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/30.png\" class=\"question-img\" /><br><img src=\"/img/question/fp2q/31.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "",
        "answer": "110,218,10",
        "score": "2,2,2",
        "image": ""
    },
    {
        "id": 1127,
        "shikenId": 20,
        "parentNo": 2,
        "no": 28,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/32.png\" class=\"question-img\" /><br><img src=\"/img/question/fp2q/33.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4|5|6",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,3,1,1",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1128,
        "shikenId": 20,
        "parentNo": 1,
        "no": 29,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/34.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "1",
        "score": "1",
        "image": ""
    },
    {
        "id": 1129,
        "shikenId": 20,
        "parentNo": 1,
        "no": 30,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/35.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "4",
        "score": "2",
        "image": ""
    },
    {
        "id": 1130,
        "shikenId": 20,
        "parentNo": 2,
        "no": 31,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/36.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "68",
        "score": "2",
        "image": ""
    },
    {
        "id": 1131,
        "shikenId": 20,
        "parentNo": 2,
        "no": 32,
        "groupId": 1,
        "answerType": 4,
        "inputType": 9,
        "question1": "<img src=\"/img/question/fp2q/37.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "1,2,1,2",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1132,
        "shikenId": 20,
        "parentNo": 2,
        "no": 33,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/38.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "330500",
        "score": "2",
        "image": ""
    },
    {
        "id": 1133,
        "shikenId": 20,
        "parentNo": 2,
        "no": 34,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/39.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2840",
        "score": "2",
        "image": ""
    },
    {
        "id": 1134,
        "shikenId": 20,
        "parentNo": 2,
        "no": 35,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/40.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "2,4,6",
        "score": "1,1,1",
        "image": ""
    },
    {
        "id": 1135,
        "shikenId": 20,
        "parentNo": 2,
        "no": 36,
        "groupId": 1,
        "answerType": 4,
        "inputType": 8,
        "question1": "<img src=\"/img/question/fp2q/41.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "227",
        "score": "1",
        "image": ""
    },
    {
        "id": 1136,
        "shikenId": 20,
        "parentNo": 2,
        "no": 37,
        "groupId": 1,
        "answerType": 4,
        "inputType": 10,
        "question1": "<img src=\"/img/question/fp2q/42.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8|9|10|11|12",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "answer": "8,1,8,3",
        "score": "1,1,1,1",
        "image": ""
    },
    {
        "id": 1137,
        "shikenId": 20,
        "parentNo": 1,
        "no": 38,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/43.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "2",
        "score": "2",
        "image": ""
    },
    {
        "id": 1138,
        "shikenId": 20,
        "parentNo": 1,
        "no": 39,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/44.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "2",
        "score": "1",
        "image": ""
    },
    {
        "id": 1139,
        "shikenId": 20,
        "parentNo": 1,
        "no": 40,
        "groupId": 1,
        "answerType": 4,
        "inputType": 4,
        "question1": "<img src=\"/img/question/fp2q/45.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "4",
        "choice5": "",
        "answer": "4",
        "score": "1",
        "image": ""
    }
]